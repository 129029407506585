import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BbcComponent } from './stappen/bbc/bbc.component';
import { ClubComponent } from './stappen/club/club.component';
import { ContactComponent } from './stappen/contact/contact.component';
import { FaqComponent } from './stappen/faq/faq.component';
import { HomeComponent } from './stappen/home/home.component';
import { InfoComponent } from './stappen/info/info.component';
import { NewsComponent } from './stappen/news/news.component';
import { ProgrammaComponent } from './stappen/programma/programma.component';
import { SpeurtochtComponent } from './stappen/speurtocht/speurtocht.component';
import { TeamsComponent } from './stappen/teams/teams.component';
import { ToolsComponent } from './stappen/tools/tools.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'bbc', component: BbcComponent },
  { path: 'club', component: ClubComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'home', component: HomeComponent },
  { path: 'info', component: InfoComponent },
  { path: 'news', component: NewsComponent },
  { path: 'programma', component: ProgrammaComponent },
  { path: 'teams', component: TeamsComponent },
  { path: 'tools', component: ToolsComponent },
  { path: 'kamp', component: SpeurtochtComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
