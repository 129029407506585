<header class="mobileHeader" [ngClass]='{ "menuOpen": menuOpen }'>
  <ng-container *ngIf="!menuOpen">
    <div class="logo" [ngStyle]="{'background-image': 'url(' + imagelogo + ')'}"></div>
    <div class="headerTitle"><i class="fa" [ngClass]="geefIcon(pagina)"></i>{{pagina}}</div>
  </ng-container>
  <div *ngIf="menuOpen" routerLink="/" class="homelink" [ngClass]='{ "spin": menuOpen }'><i class="fa fa-home"></i></div>
  <div *ngIf="menuOpen" class="logo" [ngStyle]="{'background-image': 'url(' + imagelogo + ')'}"></div>
  <div class="menuBtn" (click)="clickMenu()">
    <span class="menuLines"></span>
  </div>
</header>

<div class="mainContainer">
  <ul class="mobileMenu" [ngClass]='{ "flip": menuOpen }'>
    <li routerLink="/programma" routerLinkActive="active" (click)="clickMenuLink()"><i
        class="fa fa-sitemap"></i><span>Programma</span></li>
    <li routerLink="/news" routerLinkActive="active" (click)="clickMenuLink()"><i
        class="fa fa-newspaper-o"></i><span>Nieuws</span></li>
    <li routerLink="/teams" routerLinkActive="active" (click)="clickMenuLink()"><i
        class="fa fa-users"></i><span>Teams</span></li>
    <li routerLink="/info" routerLinkActive="active" (click)="clickMenuLink()"><i
        class="fa fa-info-circle"></i><span>Informatie</span></li>
    <li routerLink="/info" routerLinkActive="active" (click)="clickMenuLink()"><i
        class="fa fa-info-circle"></i><span>FAQ</span></li>
    <li routerLink="/club" routerLinkActive="active" (click)="clickMenuLink()"><i
        class="fa fa-dribbble"></i><span>Club</span></li>
    <li routerLink="/contact" routerLinkActive="active" (click)="clickMenuLink()"><i
        class="fa fa-envelope-open-o"></i><span>Contact</span></li>
  </ul>
  <div class="content" [ngClass]='{ "flip": menuOpen }'>
    <div class="col-left">
      <div class="col-left-top">
        <div class="logo" routerLink="/" [ngStyle]="{'background-image': 'url(' + imagelogo + ')'}">
        <div class="clock">
          <div class="middle"></div>
          <div class="clock-face" id="clock-face" #clockface>
            <time datetime="12:00">12</time>
            <time datetime="1:00">1</time>
            <time datetime="2:00">2</time>
            <time datetime="3:00">3</time>
            <time datetime="4:00">4</time>
            <time datetime="5:00">5</time>
            <time datetime="6:00">6</time>
            <time datetime="7:00">7</time>
            <time datetime="8:00">8</time>
            <time datetime="9:00">9</time>
            <time datetime="10:00">10</time>
            <time datetime="11:00">11</time>
            <span class="arm seconds"></span>
            <span class="arm minutes"></span>
            <span class="arm hours"></span>
          </div>
        </div></div>
        <ul>
          <li routerLink="/programma" routerLinkActive="active">Programma</li>
          <li routerLink="/news" routerLinkActive="active">Nieuws</li>
          <li routerLink="/teams" routerLinkActive="active">Teams</li>
          <li routerLink="/info" routerLinkActive="active">Informatie</li>
          <li routerLink="/faq" routerLinkActive="active">FAQ</li>
          <li routerLink="/club" routerLinkActive="active">Club</li>
          <li routerLink="/contact" routerLinkActive="active">Contact</li>
        </ul>
      </div>
      <div class="col-left-bottom">
        <div>
          <a href="mailto:info@blackshots.nl" target="_blank" class="btn bd-ra" title="Stuur e-mail"><span
              class="fa fa-envelope-o"></span></a>
          <a href="http://www.facebook.com/pages/Basketbalvereniging-Black-Shots/103597959807970" target="_blank"
            class="btn bd-ra" title="Facebook"><span class="fa fa-facebook"></span></a>
          <a href="http://instagram.com/blackshotsbasketball" target="_blank" class="btn bd-ra" title="Instagram"><span
              class="fa fa-instagram"></span></a>
          <a href="http://twitter.com/black__shots" target="_blank" class="btn bd-ra" title="Twitter"><span
              class="fa fa-twitter"></span></a>
        </div>
        <div class="footer-copyright" (click)="naarTools()">
          © <span id="copyright-year">{{jaar}}</span> Black Shots
        </div>
      </div>
    </div>

    <div class="main fade" [ngClass]="pagina" [ngStyle]="{'background-image': 'url(' + image + ')'}">
      <div class="main-content">
        <ng-content></ng-content>
        <app-scrolltop></app-scrolltop>
      </div>
    </div>

    <div class="col-right">
      <ng-content select="[right]"></ng-content>
    </div>
  </div>
</div>
