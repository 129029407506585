<app-contentpage [pagina]="'programma'" [image]="getImageUrl('pink.jpg')">
  <div class="title">Wedstrijden</div>
  <ng-container *ngIf="(data$ | async) as d">
    <p>Alle wedstrijden inclusief taken zijn <a href="./assets/docs/wedstrijdtakenoverzicht.pdf" target="_blank"
        class="button">hier</a> terug te vinden.
      <ng-container *ngIf="geSpeeld(d.wedstrijden).length > 0">
        <a class="hand" (click)="scrollToElement('uitslagen')">Uitslagen</a> staan hieronder.
      </ng-container>
    </p>
    <ng-container *ngIf="teSpelen(d.wedstrijden).length > 0">
      <div class="table-container col-wed" role="table">
        <div class="flex-table header" role="rowgroup">
          <div class="flex-row first" role="columnheader">Datum</div>
          <div class="flex-row" role="columnheader">Thuis</div>
          <div class="flex-row" role="columnheader">Uit</div>
          <div class="flex-row" role="columnheader">Hal</div>
          <div class="flex-row" role="columnheader">Plaats</div>
        </div>
        <div *ngFor="let w of teSpelen(d.wedstrijden)" class="flex-table row" role="rowgroup">
          <div class="flex-row first" role="cell">{{w.datum | date:'dd-MM-yyyy H:mm'}}</div>
          <div class="flex-row" role="cell">{{w.thuis_ploeg}}</div>
          <div class="flex-row" role="cell">{{w.uit_ploeg}}</div>
          <div class="flex-row" role="cell">{{w.loc_naam}}</div>
          <div class="flex-row" role="cell">{{w.loc_plaats}}</div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="geSpeeld(d.wedstrijden).length > 0">
      <div class="title" id="uitslagen">Uitslagen</div>
      <div class="table-container col-uitslag" role="table">
        <div class="flex-table header" role="rowgroup">
          <div class="flex-row first" role="columnheader">Datum</div>
          <div class="flex-row" role="columnheader">Thuis</div>
          <div class="flex-row" role="columnheader">Uit</div>
          <div class="flex-row" role="columnheader">Plaats</div>
          <div class="flex-row last" role="columnheader">Uitslag</div>
        </div>
        <div *ngFor="let w of geSpeeld(d.wedstrijden)" class="flex-table row" role="rowgroup">
          <div class="flex-row first" role="cell">{{w.datum | date:'dd-MM-yyyy H:mm'}}</div>
          <div class="flex-row" role="cell">{{w.thuis_ploeg}}</div>
          <div class="flex-row" role="cell">{{w.uit_ploeg}}</div>
          <div class="flex-row" role="cell">{{w.loc_plaats}}</div>
          <div class="flex-row last" role="cell">{{w.score_thuis}}-{{w.score_uit}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container right>
    <a href='#/bbc'><img class="imgbbc" src="./assets/images/bbc.png"></a>
  </ng-container>
</app-contentpage>
