<app-contentpage [pagina]="'info'" [image]="getImageUrl('hal.jpg')">
  <div class="showMobile links">
    <a (click)="scrollToElement('contributie')">Contributie</a>
    <a (click)="scrollToElement('leeftijd')">Leeftijdsindeling</a>
    <a (click)="scrollToElement('tenue')">Tenue</a>
    <a (click)="scrollToElement('coach')">Coaches - wedstrijden</a>
    <a (click)="scrollToElement('trainer')">Trainers - trainingen</a>
    <a (click)="scrollToElement('fluiten')">Fluiten en tafelen</a>
    <a (click)="scrollToElement('clubsup')">Clubsupport</a>
    <a (click)="scrollToElement('hallen')">Hallen</a>
    <a (click)="scrollToElement('gedrag')">Gedragsregels</a>
    <a (click)="scrollToElement('privacy')">Privacy</a>
    <a (click)="scrollToElement('sportlink')">Sportlink</a>
  </div>

  <div>
    <div id="proef" [ngClass]="(option === 'Proeftraining') ? 'show' : 'showMobile'">
      <div class="title">Proeftraining</div>
      <p>LET OP: voor spelers die zijn geboren in 2009 of later, is het helaas niet mogelijk om een proeftraining te
        volgen. De groep zit momenteel helemaal vol.</p>
      <p>Als je interesse hebt in basketballen, dan ben je van harte welkom om 2 proeftrainingen te volgen. Kijk bij de
        leeftijdsindeling bij welk team je hoort, dit is op basis van geboortejaar. Bij het bijbehorende <a
          routerLink="/teams">team</a> kun je zien hoe laat en waar de training is. Je kunt je op de aangegeven tijd
        melden bij de trainer of vooraf even mailen naar <a
          href="mailto:info@blackshots.nl?subject=Proeftraining">info&#64;blackshots.nl</a>, zodat een trainer hiermee
        rekening kan houden bij het voorbereiden van zijn trainingen.
      </p>
      <p>Er zijn geen trainingen op de volgende dagen: {{geentrainingdatums}}.</p>
    </div>

    <div id="contributie" [ngClass]="(option === 'Contributie') ? 'show' : 'showMobile'">
      <div class="title first">Contributie</div>
      <p>
        De contributie bedraagt per jaar:
      </p>
      <div class="table-container" role="table">
        <div class="flex-table header" role="rowgroup">
          <div class="flex-row first" role="columnheader">Competitie spelende leden:</div>
          <div class="flex-row" role="columnheader">Bond</div>
          <div class="flex-row" role="columnheader">Vereniging</div>
          <div class="flex-row" role="columnheader">Totaal</div>
        </div>
        <div *ngFor="let c of contributiewed" class="flex-table row" role="rowgroup">
          <div class="flex-row first" role="cell">{{c.categorie}}</div>
          <div class="flex-row" role="cell">{{c.bedragbond}}</div>
          <div class="flex-row" role="cell">{{c.bedragver}}</div>
          <div class="flex-row" role="cell">{{c.bedragtotaal}}</div>
        </div>
      </div>
      <div class="table-container cols3" role="table">
        <div class="flex-table header" role="rowgroup">
          <div class="flex-row" role="columnheader">Recreatief spelende leden:</div>
          <div class="flex-row" role="columnheader">Vereniging</div>
          <div class="flex-row" role="columnheader">Totaal</div>
        </div>
        <div *ngFor="let c of contributierec" class="flex-table row" role="rowgroup">
          <div class="flex-row" role="cell">{{c.categorie}}</div>
          <div class="flex-row" role="cell">{{c.bedragver}}</div>
          <div class="flex-row" role="cell">{{c.bedragtotaal}}</div>
        </div>
      </div>
      <p>De jaarlijkse bondscontributie wordt via automatische incasso geïnd aan het begin van het seizoen of bij
        inschrijving indien men wedstrijden gaat spelen. De verenigingscontributie wordt via automatische incasso in 4
        delen geïnd, dit gebeurd in <strong>september, december, februari en mei</strong>. Wanneer er wordt besloten
        om na recreatief basketballen toch wedstrijden te gaan spelen, wordt de bondscontributie de eerst volgende
        incassodatum geïnd. De bondscontributie is alleen van toepassing voor competitie spelende leden. Ons
        rekeningnummer is NL32RABO0112338089 van de Rabobank.</p>
      <p>
        De leeftijd op 1 januari van het seizoen is bepalend. Bij de contributie zijn de kosten voor een tenue niet
        opgenomen.</p>
      <p>
        Ouders met een laag inkomen kunnen via 'Leergeld' een bijdrage in de contributiekosten en kleding ontvangen. Op
        de <a href="https://www.leergeld.nl/helmond" target="_blank">website van leergeld</a> kunt u kijken of u
        hiervoor in
        aanmerking komt.
      </p>
      <p>Regels bij inschrijven:</p>
      <ul>
        <li>Lid worden vanaf 16 juni t/m 16 september, bondscontributie en termijn 1, 2, 3 en 4 verschuldigd</li>
        <li>Lid worden vanaf 16 september t/m 16 december, bondscontributie en termijn 2, 3 en 4 verschuldigd</li>
        <li>Lid worden vanaf 16 december t/m 16 februari, bondscontributie en termijn 3 en 4 verschuldigd</li>
        <li>Lid worden vanaf 16 februari t/m 16 juni, bondscontributie en termijn 4 verschuldigd</li>
      </ul>
      <p>Regels bij uitschrijven:</p>
      <ul>
        <li>Opzeggen tussen 1 september en 1 november, bondscontributie en termijn 1</li>
        <li>Opzeggen tussen 1 november en 1 februari, bondscontributie en termijn 1 en 2</li>
        <li>Opzeggen tussen 1 februari en 1 april, bondscontributie en termijn 1, 2 en 3</li>
        <li>Opzeggen tussen 1 april en 1 september, bondscontributie en termijn 1, 2, 3 en 4</li>
      </ul>
    </div>

    <div id="leeftijd" [ngClass]="(option === 'Leeftijdsindeling') ? 'show' : 'showMobile'">
      <div class="title">Leeftijdsindeling</div>
      <p>
        Een lid wordt in een leeftijdscategorie ingedeeld op basis van zijn / haar geboorte&shy;datum. Hieronder de
        indeling voor het seizoen {{seizoen}}.
      </p>
      <div class="table-container cols3" role="table">
        <div class="flex-table header" role="rowgroup">
          <div class="flex-row first" role="columnheader">Categorie</div>
          <div class="flex-row" role="columnheader">Leeftijd</div>
          <div class="flex-row" role="columnheader">Geboortejaar</div>
        </div>
        <div *ngFor="let l of leeftijden" class="flex-table row" role="rowgroup">
          <div class="flex-row first" role="cell">{{l.categorie}}</div>
          <div class="flex-row" role="cell">{{l.leeftijd}}</div>
          <div class="flex-row" role="cell">{{l.jaar}}</div>
        </div>
      </div>
    </div>

    <div id="tenue" [ngClass]="(option === 'Tenue') ? 'show' : 'showMobile'">
      <div class="title">Tenue</div>
      <p>
        Het tenue van Black Shots is van Macron. Om te bestellen heb je een rugnummer nodig, heb je deze nog niet stuur
        dan even een mailtje naar de <a
          href="mailto:ledenadministratie@blackshots.nl?subject=Rugnummer">ledenadministratie</a>. Een tenue kan
        besteld worden in de <a href="https://macronstoredeurne.nl/product-categorie/clubs/indoor/blackshots"
          target="_blank">webshop van Macron Deurne</a> of in de winkel zelf. Het wordt dan ter plekke bedrukt. Zorg dat
        je weet wat je rugnummer gaat zijn.
      </p>
      <br>
      <img class="imgtenue" src="./assets/images/tenue-shirt.jpg">
      <img class="imgtenue" src="./assets/images/tenue-short.jpg">
    </div>

    <div id="coach" [ngClass]="(option === 'Coaches - wedstrijden') ? 'show' : 'showMobile'">
      <div class="title">Coaches - wedstrijden</div>
      <p>Behalve een eigen trainer heeft elk team natuurlijk een coach. Deze verzorgt de begeleiding van de wedstrijden,
        eventueel in overleg met de trainer.</p>
      <p>Een coach is verantwoordelijk voor:</p>
      <ul>
        <li>de veiligheid van de spelers</li>
        <li>het vervoer naar een sporthal</li>
        <li>een goede sfeer</li>
        <li>het tijdig starten van een wedstrijd</li>
        <li>een goede verdeling van de speeltijd per speler</li>
      </ul>
      <p>
        De trainers en coaches hebben aan het begin van het seizoen een bijeenkomst waarin verschillende afspraken
        gemaakt kunnen worden omtrent de trainingen en wedstrijden. Mocht er behoefte aan zijn dan kan er vaker overleg
        plaatsvinden en ook het bestuur staat altijd ter beschikking.
      </p>
    </div>

    <div id="trainer" [ngClass]="(option === 'Trainers - trainingen') ? 'show' : 'showMobile'">
      <div class="title">Trainers - trainingen</div>
      <p>Elk team heeft een trainer, dit is meestal een enthousiast lid uit het bestuur of een ouder. De trainer dient
        een training te geven met het doel dat de leden zich verder ontwikkelen op het gebied van basketball.</p>
      <p>De trainer is verantwoordelijk voor:</p>
      <ul>
        <li>de veiligheid van de spelers</li>
        <li>een goede organisatie en planning van de training</li>
        <li>een goede sfeer</li>
        <li>het materiaal</li>
        <li>vervanging als men zelf niet kan</li>
      </ul>
      <p>
        De locaties in Helmond waar de trainingen van Black Shots worden gegeven zijn het Veka Sportcentrum Deltaweg
        201, sportzaal aan de Biezenlaan 29 en de Westwijzer Cortenbachstraat 70. In het Veka Sportcentrum zijn 3
        trainingsvelden of 2 wedstrijdvelden beschikbaar. Voor de trainingen zijn in de Biezenlaan 2 velden beschikbaar.
        Kijk bij de <a href="#/teams">teams</a> hoe laat welke training waar en door wie wordt gegeven. Voordat je je
        aanmeldt, mag je 2 proef&shy;trainingen meedraaien. Je kunt je op de aangegeven tijd melden bij de trainer of
        vooraf even mailen naar <a href="mailto:info@blackshots.nl?subject=Proeftraining">info&#64;blackshots.nl</a>,
        zodat
        een trainer hiermee rekening kan houden bij het voorbereiden van zijn trainingen.
      </p>
      <p>{{geentrainingkop}} Er zijn geen trainingen op de volgende dagen:</p>
      <ul>
        <li *ngFor="let g of geentrainingen">{{g.datum}} i.v.m. {{g.reden}}</li>
      </ul>
    </div>

    <div id="fluiten" [ngClass]="(option === 'Fluiten en tafelen') ? 'show' : 'showMobile'">
      <div class="title">Fluiten en tafelen</div>
      <p>
        Om onze wedstrijden in goede banen te leiden, dienen scheidsrechters en tafelaars op de hoogte te zijn van de
        geldende regels. De onderstaande documenten gaan over een aantal belangrijke aspecten. Klik om ze te downloaden
        en te printen. Heb je vragen? Stel ze aan de trainers of coaches.
      </p>
      <ul>
        <li><a href="./assets/docs/Hand-out_verenigingscursus.pdf" target="_blank">Handout van de
            verenigingscursus</a> (voor scheidsrechters, 38 pag.)</li>
        <li><a href="https://www.youtube.com/watch?v=zrEf0v95Kx8" target="_blank">Uitleg gebruik tablet - youtube</a>
        </li>
        <li><a href="./assets/docs/dwf_assist.pdf" target="_blank">Uitleg gebruik tablet - pdf</a></li>
        <li><a href="./assets/docs/handleiding%20scorebord.pdf" target="_blank">Instructies voor het scorebord</a></li>
        <li><a href="./assets/docs/a4tje%20spelregels.pdf" target="_blank">Belangrijkste verschillen van de
            miniregels</a></li>
      </ul>
    </div>

    <div id="clubsup" [ngClass]="(option === 'Clubsupport') ? 'show' : 'showMobile'">
      <div class="title">Clubsupport</div>
      <p>
        Op 1 oktober 2013 hebben Black Shots te Helmond en Erik Borsboom van Fysiotherapie Jeurissen &amp; van den Ingh,
        in het kader van Clubsupport, een samenwerkings&shy;overeenkomst getekend.
      </p>
      <p>
        Clubsupport richt zich op educatie, preventie, blessurebegeleiding en -behandeling en heeft als doel de
        sportmedische begeleiding bij Black Shots te optimaliseren. Hiervoor is een inloopspreekuur in het leven
        geroepen bij Fysiotherapie Jeurissen &amp; van den Ingh:
      </p>
      <div class="table-container cols3" role="table">
        <div class="flex-table header" role="rowgroup">
          <div class="flex-row first" role="columnheader">Plaats</div>
          <div class="flex-row" role="columnheader">Dag</div>
          <div class="flex-row" role="columnheader">Tijd</div>
        </div>
        <div class="flex-table row" role="rowgroup">
          <div class="flex-row first" role="cell">Gemert</div>
          <div class="flex-row" role="cell">maandagavond</div>
          <div class="flex-row" role="cell">19:00-20:00</div>
        </div>
        <div class="flex-table row" role="rowgroup">
          <div class="flex-row first" role="cell">Deurne</div>
          <div class="flex-row" role="cell">maandagochtend<br>dinsdagavond</div>
          <div class="flex-row" role="cell">07:30-09:30<br>20:00-21:00</div>
        </div>
        <div class="flex-table row" role="rowgroup">
          <div class="flex-row first" role="cell">Mierlo</div>
          <div class="flex-row" role="cell">dinsdagavond</div>
          <div class="flex-row" role="cell">18:30-19:30</div>
        </div>
      </div>
      <p>
        De leden van Black Shots kunnen hier kosteloos, snel en vakkundig advies krijgen zonder een afspraak te hoeven
        maken. Zodoende kan een individueel advies of eventuele reguliere, sport of manueel fysiotherapeutische
        begeleiding plaatsvinden. Daarnaast zal fysiotherapie Jeurissen &amp; Van den Ingh begeleiding bieden binnen het
        netwerk van behandelaars, ziekenhuizen en behandelcentra. Verder zal Fysiotherapie Jeurissen en van den Ingh
        zich bij Black Shots inzetten op preventief gebied, door middel van het geven van workshops. Middels clubsupport
        wil Black Shots en Fysiotherapie Jeurissen &amp; Van den Ingh de leden van Black Shots een zo optimaal mogelijke
        begeleiding bieden voor het herstel van de klachten zodat werk en sport snel en verantwoord hervat kan worden.
        Kijk <a href="http://www.jvdi.nl/clubsupport">hier</a> voor meer informatie.
      </p>
    </div>

    <div id="hallen" [ngClass]="(option === 'Hallen') ? 'show' : 'showMobile'">
      <div class="title">Hallen</div>
      <h2>Veka Sportcentrum</h2>
      <p>Adres: Deltaweg 201 Helmond, telefoon: 0492 510 119</p>
      <div class="maps">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCRZky3B0SfuY0ukrrcLh1qLZ9vVuP6MJs&amp;q=Veka+Sportcentrum,Helmond+Nederland"
          allowfullscreen=""></iframe>
      </div>
      <h2>Sporthal de Veste</h2>
      <p>Adres: Biezenlaan 27 Helmond</p>
      <div class="maps">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCRZky3B0SfuY0ukrrcLh1qLZ9vVuP6MJs&amp;q=Biezenlaan+27,Helmond+Nederland"
          allowfullscreen=""></iframe>
      </div>
      <h2>Sportzaal de Westwijzer</h2>
      <p>Adres: Cortenbachstraat 70</p>
      <div class="maps">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCRZky3B0SfuY0ukrrcLh1qLZ9vVuP6MJs&amp;q=Cortenbachstraat+70,Helmond+Nederland"
          allowfullscreen=""></iframe>
      </div>
    </div>

    <div id="gedrag" [ngClass]="(option === 'Gedragsregels') ? 'show' : 'showMobile'">
      <div class="title">Gedragsregels</div>
      <h2>Een trainer, coach of begeleider:</h2>
      <ul>
        <li>Zorgt voor een veilige omgeving. Schept een omgeving en een sfeer, waarin sociale veiligheid gewaarborgd is
          en ook zo wordt ervaren.</li>
        <li>Is zorgvuldig en oprecht bij het vermelden van ervaring en functies. Vermeldt alle relevante feiten bij de
          aanstelling als trainer, coach of begeleider.</li>
        <li>Is zich bewust van machtsongelijkheid en (soms ook) afhankelijkheid en misbruikt zijn positie niet. Gebruikt
          de positie niet om op onredelijke of ongepaste wijze macht uit te oefenen. Onthoudt zich van elke vorm van
          (machts)misbruik, emotioneel misbruik, fysiek grens&shy;overschrijdend gedrag, waaronder seksueel getinte
          opmerkingen, en aanrakingen en seksueel misbruik. Alle seksuele handelingen, - contacten en - relaties met
          minderjarigen zijn onder geen beding geoorloofd.</li>
        <li>Respecteert het priveleven van de sporter. Dringt niet verder in het privéleven van sporters dan
          noodzakelijk is. Gaat met respect om met de sporter en met ruimtes waarin de sporters zich bevinden, zoals de
          kleedkamer of doucheruimte.</li>
        <li>Tast niemand in zijn waarde aan: onthoudt zich van discriminerende, kleinerende of intimiderende opmerkingen
          en gedragingen. Maakt geen onderscheid naar godsdienst, levensovertuiging, politieke gezindheid, ras,
          geslacht, seksuele gerichtheid, culturele achtergrond, leeftijd of andere kenmerken. Sluit niemand buiten en
          is tolerant.</li>
        <li>Is een voorbeeld voor anderen en onthoudt zich van gedragingen en uitlatingen waardoor de sport in
          diskrediet wordt gebracht. Gedraagt zich hoffelijk en respectvol, onthoudt zich van grievende, en/ of
          beledigende opmerkingen.</li>
        <li>Neemt geen gunsten, geschenken, diensten of vergoedingen aan, om iets te doen of na te laten wat in strijd
          is met de integriteit van de sport. Word je iets aangeboden om iets te doen of na te laten, meld dit dan aan
          het bestuur.</li>
        <li>Biedt geen gunsten, geschenken, diensten of vergoedingen aan, om iets te doen of na te laten wat in strijd
          is met de integriteit van de sport.</li>
        <li>Ziet toe op naleving van regels en normen. Ziet toe op de naleving van de reglementen, de huisregels, deze
          gedragscode en andere normen.</li>
        <li>Is open en alert op waarschuwingssignalen. Is waakzaam en alert op signalen en aarzel niet om signalen
          door te geven aan het bestuur, de vertrouwens(contact)persoon en/ of contact op te nemen met het
          vertrouwenspunt sport.</li>
        <li>Is voorzichtig: stelt nooit informatie beschikbaar, die nog niet openbaar is gemaakt en kan worden gebruikt
          voor het plaatsen van weddenschappen.</li>
        <li>Drinkt tijdens het coachen van jeugdteams geen alcohol en maakt een afspraak met jeugdteams dat er geen
          alcohol wordt gedronken.</li>
      </ul>

      <h2>Een bestuurder of andere functionaris:</h2>
      <ul>
        <li>Zorgt voor een veilige omgeving. Schept een omgeving en een sfeer, waarin sociale veiligheid gewaarborgd is
          en ook zo wordt ervaren.</li>
        <li>Is dienstbaar. Handelt altijd in het belang van de vereniging of andere rechtspersoon en richt zich op het
          belang van de leden, en of aangeslotenen.</li>
        <li>Is open. Handelt zo transparant mogelijk, zodat het eenvoudig is om verantwoording af te leggen en inzicht
          bestaat in het handelen en de beweegredenen.</li>
        <li>Is betrouwbaar. Houdt zich aan regels, waaronder de statuten, reglementen en besluiten van Black Shots en
          afspraken. Informatie wordt gebruikt voor het doel van de vereniging. Verklaart vertrouwelijke informatie niet
          voor eigen gewin of ten gunste van anderen te gebruiken.</li>
        <li>Is zorgvuldig. Handelt met respect en stelt gelijke behandeling voorop. Belangen worden op een correcte
          wijze gewogen. Is zorgvuldig en oprecht bij het vermelden van ervaring en functies. Gaat zorgvuldig en correct
          om met vertrouwelijke informatie. Zal bestuursbesluiten goed onderbouwen zodat men begrip heeft voor de
          gekozen richting.</li>
        <li>Voorkomt de (schijn van) belangen&shy;verstrengeling. Vervult geen nevenfuncties die in strijd zijn, of
          kunnen zijn met zijn functie en gaat geen financieel belang aan dat in strijd kan zijn met zijn functie.
          Bespreekt het voornemen tot het aangaan van een nevenfunctie of van een financieel belang in een vereniging
          met verantwoordelijken. Doet opgave van financiële belangen in andere verenigingen en van nevenfuncties. Geeft
          aan of de nevenfuncties bezoldigd of onbezoldigd zijn. Voorkomt bij samenwerkingsvormen en -relaties de schijn
          van bevoordeling in strijd met eerlijke concurrentie&shy;verhoudingen.</li>
        <li>Neemt geen geschenken of giften aan die bestemd zijn om een persoonlijk voordeel te geven.</li>
        <li>Is een voorbeeld voor anderen en onthoudt zich van gedragingen en uitlatingen waardoor de sport in
          diskrediet wordt gebracht. Gedraagt zich hoffelijk en respectvol, onthoudt zich van grievende en/of
          beledigende opmerkingen.</li>
        <li>Neemt (meldingen van) onbehoorlijk en, of grens&shy;overschrijdend gedrag serieus. Spant zich in om het
          onderwerp integriteit bespreekbaar te maken en te houden. Zorgt voor een bepaalde mate van alertheid in de
          vereniging voor onbehoorlijk en/ of grens&shy;overschrijdend gedrag. Stimuleert het melden van ongewenst
          gedrag. Treedt adequaat op tegen het schenden van regels en normen door sporters, werknemers, supporters en
          anderen.</li>
        <li>Spant zich in om in zee te gaan met integere functionarissen, leveranciers, sponsors, e.a. Tracht te komen
          tot een situatie waarin de sportorganisatie intern en extern handelt met personen en verenigingen die van
          onbesproken gedrag zijn. Gaat na of een functionaris van onbesproken gedrag is, vraagt een Verklaring Omtrent
          Gedrag (VOG) en doet onderzoek, in relatie tot de beoogde functie. Verricht onderzoek naar handelspartners,
          e.a.
        </li>
        <li>Ziet toe op naleving van regels en normen. Ziet toe op de naleving van de reglementen, de huisregels, deze
          gedragscode en andere normen.</li>
      </ul>

      <h2>Een scheidsrechter of official:</h2>
      <ul>
        <li>Is neutraal bij het leiden of jureren van wedstrijden en voorkomt de (schijn van)
          belangen&shy;verstrengeling.</li>
        <li>Gaat respectvol om met alle betrokkenen.</li>
        <li>Zorgt voor een veilige omgeving in en rond de wedstrijd in samenwerking met de trainers/coaches en
          begeleiders. Schept een omgeving en een sfeer, waarin sociale veiligheid gewaarborgd is en ook zo wordt
          ervaren.</li>
        <li>Organiseert een goede samenwerking met de andere arbitragefunctionarissen, die in de wedstrijd actief zijn
          (medescheidsrechters, jurytafel, etc.).</li>
        <li>Is dienstbaar, zowel bij het faciliteren van een sportief verloop van de wedstrijd als bij het uitvoeren van
          het beleid rond sportief gedrag.</li>
        <li>Ziet toe op naleving van regels en normen in samenwerking met de trainers/coaches en begeleiders. Ziet toe
          op de naleving van de reglementen, de huisregels, deze gedragscode en andere normen.</li>
        <li>Is open. Handelt zo transparant mogelijk, zodat het eenvoudig is om verantwoording af te leggen en inzicht
          bestaat in het handelen en de beweegredenen.</li>
        <li>Is een voorbeeld voor anderen en onthoudt zich van gedragingen en uitlatingen waardoor de sport in
          diskrediet wordt gebracht, ook bij het gebruik van social media.</li>
        <li>Neemt geen gunsten, geschenken, diensten of vergoedingen van spelers, trainers/coaches, bestuurders, of
          derden aan, om iets te doen of na te laten wat in strijd is met de integriteit van de sport. Wordt iets
          aangeboden om iets te doen of na te laten, dan meldt hij dit aan het bestuur.</li>
        <li>Is collegiaal t.o.v. andere scheidsrechters en officials, ook als hij/zij toeschouwer is bij een
          collega-scheidsrechter.</li>
      </ul>

      <h2>De Sporter:</h2>
      <ul>
        <li>Is open: wanneer je iets wordt gevraagd, iets te doen wat tegen je eigen gevoel, je normen en waarden
          ingaat, meld dit, bijvoorbeeld bij het bestuur of vertrouwenspersoon. Voor vragen en meldingen kun je ook
          terecht bij het vertrouwenspunt sport. Ook wanneer je wordt benaderd om vals te spelen, meld dit.</li>
        <li>Toont respect: voor de tegenstander(s), je teamgenoten, de scheidsrechter, je trainers, de toeschouwers en
          ieder ander. Let op taalgebruik en hoe je je aan anderen presenteert. Geeft iedereen het gevoel dat hij of
          zij zich vrij kan bewegen.</li>
        <li>Respecteert afspraken: Kom op tijd, meld je (tijdig) af, luister naar instructies en houd je aan de regels.
        </li>
        <li>Gaat netjes om met de omgeving: maakt niets stuk, respecteert ieders eigendommen, laat de kleedkamer netjes
          achter. Ruimt de materialen op. Gooit afval in de afvalbakken.</li>
        <li>Blijft van anderen af: raakt buiten de normale sportbeoefening, niemand tegen zijn of haar wil aan.</li>
        <li>Houdt zich aan de regels: leest de reglementen, de huisregels, deze gedragscode en alle andere afspraken, en
          houdt zich daar ook aan.</li>
        <li>Tast niemand in zijn waarde aan: pest niet. Onthoudt zich van discriminerende, kleinerende of intimiderende
          opmerkingen en gedragingen. Sluit niemand buiten en is tolerant.</li>
        <li>Discrimineert niet: maakt geen onderscheid naar godsdienst, levensovertuiging, politieke gezindheid, ras,
          geslacht, seksuele gerichtheid, culturele achtergrond, leeftijd of andere kenmerken.</li>
        <li>Is eerlijk en sportief: speelt niet vals, gebruikt geen verbaal of fysiek geweld, gebruikt geen doping.</li>
        <li>Meldt overtredingen van deze gedragscode: meldt overtredingen van deze code bij het bestuur en/of de
          vertrouwenscontactpersoon van de sportvereniging. Voor vragen en meldingen kun je ook terecht bij het
          vertrouwenspunt sport.</li>
        <li>NB: Zijn bepaalde feiten vertrouwelijk met je gedeeld schendt dan dit vertrouwen niet. Wanneer echter de
          belangen van een lid ernstig in het geding zijn, raadpleeg dan een derde, bel bijvoorbeeld met het
          vertrouwenspunt sport.</li>
        <li>Drinkt na afloop van het sporten alcohol met mate (18+) en drinkt niet wanneer hij/zij met de auto is.</li>
      </ul>
    </div>

    <div id="privacy" [ngClass]="(option === 'Privacy') ? 'show' : 'showMobile'">
      <div class="title">Privacy</div>
      <p>De privacy van onze leden is voor het bestuur van basketbalvereniging Black Shots van groot belang. Wij houden
        ons dan ook aan de privacywet. Dit betekent dat je gegevens veilig zijn bij ons en dat wij ze altijd netjes
        gebruiken. Wij zorgen dat je gegevens bij ons goed beveiligd zijn. We passen de beveiliging steeds aan en letten
        goed op wat er mis kan gaan.</p>
      <p>Door het lidmaatschap van onze vereniging laat je bepaalde gegevens bij ons achter. Dat kunnen persoonsgegevens
        zijn. Wij bewaren en gebruiken uitsluitend de persoonsgegevens die rechtstreeks door jou worden opgegeven, in
        het kader van jouw lidmaatschap bij basketbalvereniging Black Shots, of waarvan bij opgave duidelijk is dat ze
        aan ons worden verstrekt om te verwerken.</p>
      <p>Met uitzondering van de Nederlandse Basketball Bond (NBB) en de Rabobank geven wij je persoonsgegevens onder
        geen voorwaarde aan andere bedrijven of instellingen, behalve als wij dat wettelijk verplicht zijn (bijvoorbeeld
        als de politie dat eist bij een vermoeden van een misdrijf). Als je vragen hebt of wilt weten welke
        persoonsgegevens wij van jou hebben, kun je altijd contact met ons opnemen.</p>
      <p>
        Je hebt de volgende rechten:
      </p>
      <ul>
        <li>uitleg krijgen over welke persoonsgegevens we hebben en wat we daarmee doen;</li>
        <li>inzage in de precieze persoonsgegevens die we hebben;</li>
        <li>het laten corrigeren van fouten;</li>
        <li>het laten verwijderen van verouderde persoonsgegevens;</li>
        <li>intrekken van toestemming;</li>
        <li>bezwaar maken tegen een bepaald gebruik.</li>
      </ul>
      <p></p>
      <p>Let op dat je altijd duidelijk aangeeft wie je bent, zodat we zeker weten dat we geen gegevens van de verkeerde
        persoon aanpassen of verwijderen.</p>
      <p>Als je vindt dat wij je niet op de juiste manier helpen, dan heb je het recht om een klacht in te dienen bij de
        toezichthouder. Deze heet de Autoriteit Persoonsgegevens. Op www.autoriteitpersoonsgegevens.nl lees je hoe je
        een klacht moet indienen.</p>
    </div>

    <div id="sportlink" [ngClass]="(option === 'Sportlink') ? 'show' : 'showMobile'">
      <div class="title">Sportlink</div>
      <p>
        Speciale aandacht willen wij schenken aan jouw gegevens in Sportlink. De algemene Verordening
        Gegevensbescherming stelt dat kinderen tot 16 jaar niet zelf meer mogen aangeven of zij met naam en/of foto
        zichtbaar willen zijn binnen een platform. En dit geldt dus ook voor de persoonlijke instellingen van kinderen
        onder 16 in de Sportlink basketbal app. De NBB adviseert dat alleen ouders van leden onder de 16 jaar de
        gegevens kan wijzigen in Sportlink. Op de volgende pagina heeft Sportlink toegelicht wat de ouder/ verzorger per
        situatie moet doen: <a
          href="https://sportlinkservices.freshdesk.com/solution/articles/9000149836-persoonlijke-instellingen-wijzigen-van-kinderen-onder-16-jaar"
          target="_blank">Ik ben ouder en wil de gegevens van mijn kind aanpassen</a>
      </p>
    </div>
  </div>

  <ng-container right>
    <ng-container>
      <div [@listStagger]='options.length' class="right-options">
        <div *ngFor='let opt of options' class="option" [ngClass]='{ "active": opt === option }'
          (click)="changeOption(opt)">{{opt}}</div>
      </div>
    </ng-container>
  </ng-container>
</app-contentpage>
