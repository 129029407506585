import { Component, OnInit, ViewEncapsulation, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-contentpage',
  templateUrl: './contentpage.component.html',
  styleUrls: ['./contentpage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentpageComponent implements OnInit {
  @Input() pagina: string;
  @Input() image: string;
  @ViewChild('clockface', { static: true }) clockFace: ElementRef;
  imagelogo: string;
  jaar: number;
  menuOpen: boolean;
  fadePage: boolean;
  teller = 0;

  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router) { }

  ngOnInit() {
    this.jaar = new Date().getFullYear();
    this.imagelogo = environment.imageLocation + 'logo-blackshots-zwart.svg';
    /* to set current time */
    const time = new Date();
    const hour = -3600 * (time.getHours() % 12);
    const mins = -60 * time.getMinutes();
    this.clockFace.nativeElement.style.setProperty('--_dm', `${mins}s`);
    this.clockFace.nativeElement.style.setProperty('--_dh', `${(hour + mins)}s`);
  }

  clickMenu() {
    this.menuOpen = !this.menuOpen;
    this.fadePage = false;
  }
  clickMenuLink() {
    this.menuOpen = false;
    this.fadePage = true;
  }

  geefIcon(pagina: string): string {
    switch (pagina) {
      case 'programma':
        return 'fa-sitemap';
      case 'news':
        return 'fa-newspaper-o';
      case 'teams':
        return 'fa-users';
      case 'info':
        return 'fa-info-circle';
      case 'club':
        return 'fa-dribbble';
      case 'contact':
        return 'fa-envelope-open-o';
    }
    return '';
  }

  getImage(): string {
    return this.image;
  }

  naarTools(): void {
    this.teller++;
    if (this.teller > 2) {
      this.router.navigate(['/tools']);
    }
  }
}
