import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BasePaginaDirective } from '../../directives/base/base-pagina.component';

@Component({
  selector: 'app-bbc',
  templateUrl: './bbc.component.html',
  styleUrls: ['./bbc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BbcComponent extends BasePaginaDirective implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
