<app-contentpage [pagina]="'contact'" [image]="getImageUrl('shoes.jpg')">
  <div class="showMobile links">
    <a (click)="scrollToElement('uit')">Uitschrijven</a>
    <a (click)="scrollToElement('bestuur')">Bestuur</a>
    <a (click)="scrollToElement('cies')">Commissies</a>
    <a (click)="scrollToElement('socials')">Socials</a>
  </div>

  <div [ngClass]="(option === 'Inschrijven') ? 'show' : 'showMobile'">
    <div class="title first">Inschrijven</div>
    <ng-container *ngIf="!submitSucces">
      <p>LET OP! Nog geen 2 proeftrainingen gehad, doe dat dan eerst, hier inschrijven is dan niet nodig. Je mag bij
        Black Shots twee keer gratis komen meetrainen. Kijk <a href="#/info?q=Proeftraining">hier</a> voor meer
        informatie.
      </p>
      <p>Om lid te worden hebben we enkele gegevens van je nodig. Deze gegevens kun je doorgeven via onderstaand online
        formulier.<br> Velden met een <span class="form-req-symbol">&nbsp;&nbsp;</span> zijn verplicht.</p>
      <p>Liever een papieren formulier invullen, deze is <a href="./assets/docs/inschrijfformulier-black-shots.pdf"
          target="_blank">hier</a> te vinden.</p>
    </ng-container>

    <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm" novalidate>
      <ng-container *ngIf="!submitSucces">
        <div class="form-group">
          <div class="form-group-header">NAW-gegevens</div>
          <div class="form-sub-group">
            <label>Voornaam: <span class="form-req-symbol"></span>
              <input type="text" placeholder="voornaam" name="voornaam" ngModel required #voornaam="ngModel"
                [ngClass]='{ "invalid": voornaam.invalid && (voornaam.dirty || voornaam.touched || formSubmitted) }'>
              <div *ngIf="voornaam.invalid && (voornaam.dirty || voornaam.touched || formSubmitted)" class="error">
                Voornaam is verplicht.
              </div>
            </label>

            <label>Tussen:
              <input type="text" placeholder="tussen" name="tussen" ngModel #tussen="ngModel">
            </label>

            <label>Achternaam: <span class="form-req-symbol"></span>
              <input type="text" placeholder="achternaam" name="achternaam" ngModel required #achternaam="ngModel"
                [ngClass]='{ "invalid": achternaam.invalid && (achternaam.dirty || achternaam.touched || formSubmitted) }'>
              <div *ngIf="achternaam.invalid && (achternaam.dirty || achternaam.touched || formSubmitted)"
                class="error">
                Achternaam is verplicht.
              </div>
            </label>
          </div>

          <div class="form-sub-group">
            <label>Adres: <span class="form-req-symbol"></span>
              <input type="text" placeholder="straat en huisnummer" name="adres" ngModel required #adres="ngModel"
                [ngClass]='{ "invalid": adres.invalid && (adres.dirty || adres.touched || formSubmitted) }'>
              <div *ngIf="adres.invalid && (adres.dirty || adres.touched || formSubmitted)" class="error">
                Adres is verplicht.
              </div>
            </label>

            <label>Postcode: <span class="form-req-symbol"></span>
              <input type="text" placeholder="postcode" postcode name="postcode" ngModel required #postcode="ngModel"
                pattern="\b[1-9][0-9]{3}[ ]?([A-RT-Z][A-Z]|[S][BCE-RT-Z])\b"
                [ngClass]='{ "invalid": postcode.invalid && (postcode.dirty || postcode.touched || formSubmitted) }'
                oninput="this.value = this.value.toUpperCase()">
              <div *ngIf="postcode.invalid && (postcode.dirty || postcode.touched || formSubmitted)" class="error">
                <div *ngIf="postcode.errors.required">
                  Postcode is verplicht.
                </div>
                <div *ngIf="postcode.errors.pattern">
                  Postcode heeft niet het juiste formaat.
                </div>
              </div>
            </label>

            <label>Woonplaats: <span class="form-req-symbol"></span>
              <input type="text" placeholder="woonplaats" name="plaats" ngModel required #plaats="ngModel"
                [ngClass]='{ "invalid": plaats.invalid && (plaats.dirty || plaats.touched || formSubmitted) }'>
              <div *ngIf="plaats.invalid && (plaats.dirty || plaats.touched || formSubmitted)" class="error">
                <div *ngIf="plaats.errors.required">
                  Woonplaats is verplicht.
                </div>
              </div>
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="form-group-header">Persoons-gegevens</div>
          <div class="form-sub-group">
            <label>Geboortedatum: <span class="form-req-symbol"></span>
              <input type="text" placeholder="dd-mm-yyyy" geboortedatum name="geboortedatum" ngModel required
                appGeboortedatumValidator #geboortedatum="ngModel"
                pattern="^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$"
                [ngClass]='{ "invalid": geboortedatum.invalid && (geboortedatum.dirty || geboortedatum.touched || formSubmitted) }'>
              <div *ngIf="geboortedatum.invalid && (geboortedatum.dirty || geboortedatum.touched || formSubmitted)"
                class="error">
                <div *ngIf="geboortedatum.errors.required">
                  Geboortedatum is verplicht.
                </div>
                <div *ngIf="!geboortedatum.errors.required && geboortedatum.errors.pattern">
                  Geboortedatum moet dd-mm-yyyy zijn.
                </div>
                <div
                  *ngIf="!geboortedatum.errors.required && !geboortedatum.errors.pattern && geboortedatum.errors.appGebDatumValidatorTeJong">
                  Lid is te jong, moet minimaal 5 jaar zijn.
                </div>
                <div
                  *ngIf="!geboortedatum.errors.required && !geboortedatum.errors.pattern && geboortedatum.errors.appGebDatumValidatorTeOud">
                  Lid is te oud, mag maximaal 60 jaar zijn.
                </div>
                <div
                  *ngIf="!geboortedatum.errors.required && !geboortedatum.errors.pattern && geboortedatum.errors.appGebDatumValidator">
                  Foutieve datum.
                </div>
              </div>
            </label>
          </div>

          <div class="form-sub-group">
            <label class="form-radio-group">Geslacht: <span class="form-req-symbol"></span>
              <div class="label-group">
                <label for="man"><input type="radio" id="man" name="geslacht" ngModel required #geslacht="ngModel"
                    value="man"> Man</label>
                <label for="vrouw"><input type="radio" id="vrouw" name="geslacht" ngModel required #geslacht="ngModel"
                    value="vrouw"> Vrouw</label>
                <label for="anders"><input type="radio" id="anders" name="geslacht" ngModel required #geslacht="ngModel"
                    value="anders"> Anders</label>
              </div>
              <div *ngIf="geslacht.invalid && (geslacht.dirty || geslacht.touched || formSubmitted)" class="error">
                Geslacht is verplicht.
              </div>
            </label>
          </div>
        </div>

        <div *ngIf="jongerDan18(contactForm, geboortedatum.invalid)" class="form-group">
          <div class="form-group-header">Gegevens ouders</div>
          <div class="form-sub-group">
            <label>Naam vader:
              <input type="text" placeholder="naam vader" name="naamVader" ngModel #naamVader="ngModel">
            </label>
            <label>Mobiel vader:
              <input type="text" placeholder="mobiel vader" mobielVader name="mobielVader" ngModel
                #mobielVader="ngModel">
            </label>
            <label>E-mail vader:
              <input type="text" placeholder="e-mail vader" emailadresVader name="emailadresVader" ngModel email
                #emailadresVader="ngModel">
              <div
                *ngIf="emailadresVader.invalid && (emailadresVader.dirty || emailadresVader.touched || formSubmitted)"
                class="error">
                <div *ngIf="emailadresVader.errors.email">
                  E-mail heeft niet het juiste formaat.
                </div>
              </div>
            </label>
          </div>

          <div class="form-sub-group">
            <label>Naam moeder:
              <input type="text" placeholder="naam moeder" name="naamMoeder" ngModel #naamMoeder="ngModel">
            </label>
            <label>Mobiel moeder:
              <input type="text" placeholder="mobiel moeder" mobielMoeder name="mobielMoeder" ngModel
                #mobielMoeder="ngModel">
            </label>
            <label>E-mail moeder:
              <input type="text" placeholder="e-mail moeder" emailadresMoeder name="emailadresMoeder" ngModel email
                #emailadresMoeder="ngModel">
              <div
                *ngIf="emailadresMoeder.invalid && (emailadresMoeder.dirty || emailadresMoeder.touched || formSubmitted)"
                class="error">
                <div *ngIf="emailadresMoeder.errors.email">
                  E-mail heeft niet het juiste formaat.
                </div>
              </div>
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="form-group-header">Contact-gegevens</div>
          <div class="form-sub-group">
            <label>Telefoon:
              <input type="text" placeholder="telefoon" telefoon name="telefoon" ngModel #telefoon="ngModel">
            </label>
            <label>Mobiel:
              <input type="text" placeholder="mobiel" mobiel name="mobiel" ngModel #mobiel="ngModel">
            </label>
          </div>

          <div class="form-sub-group">
            <label>E-mail: <span class="form-req-symbol"></span>
              <input type="text" placeholder="e-mail" emailadres name="emailadres" ngModel required email
                #emailadres="ngModel"
                [ngClass]='{ "invalid": emailadres.invalid && (emailadres.dirty || emailadres.touched || formSubmitted) }'>
              <div *ngIf="emailadres.invalid && (emailadres.dirty || emailadres.touched || formSubmitted)"
                class="error">
                <div *ngIf="emailadres.errors.required">
                  E-mail is verplicht.
                </div>
                <div *ngIf="emailadres.errors.email">
                  E-mail heeft niet het juiste formaat.
                </div>
              </div>
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="form-group-header">Overig</div>
          <div class="form-sub-group">
            <label class="form-radio-group">Wil je wedstrijden gaan spelen?<span class="form-req-symbol"></span>
              <div class="label-group">
                <label for="wedstrijden"><input type="radio" id="wedstrijden" wedstrijden name="wedstrijden" ngModel
                    required #wedstrijden="ngModel" value="Ja"> Ja</label>
                <label for="recreatief"><input type="radio" id="recreatief" wedstrijden name="wedstrijden" ngModel
                    required #wedstrijden="ngModel" value="Nee"> Nee</label>
              </div>
              <div *ngIf="wedstrijden.invalid && (wedstrijden.dirty || wedstrijden.touched || formSubmitted)"
                class="error">
                De keuze is verplicht.
              </div>
            </label>
            <!-- 
            <label *ngIf="wedstrijden.value === 'Ja'" >Pasfoto
              <div class="form-sub-group">
                <div *ngIf="pasfoto == null" class="upload-container">
                  <div class="upload">
                    <input class="inputfile" id="newfile" (change)="onFileChanged($event)" #newFile name="newfile" type="file" accept="image/*">
                    <label for="newfile">Klik hier om een pasfoto toe te voegen.</label>
                  </div>
                </div>
                <div *ngIf="pasfoto != null" class="thumbnail_delete">
                  <div class="thumbnail">
                    <img [src]="previewImg" />
                  </div>
                  <div class="delete" (click)="deleteFile()">
                    <img src="/assets/images/recycle-bin.svg" />
                  </div>
                </div>
              </div>
            </label> -->
          </div>

          <div class="form-sub-group">
            <label class="form-radio-group">Heb je al 2 (proef)trainingen gevolgd?<span class="form-req-symbol"></span>
              <div class="label-group">
                <label for="proeftrainingenja"><input type="radio" id="proeftrainingenja" proeftrainingen
                    name="proeftrainingen" ngModel required #proeftrainingen="ngModel" value="Ja"> Ja</label>
                <label for="proeftrainingennee"><input type="radio" id="proeftrainingennee" proeftrainingen
                    name="proeftrainingen" ngModel required #proeftrainingen="ngModel" value="Nee"> Nee</label>
              </div>
              <div
                *ngIf="proeftrainingen.invalid && (proeftrainingen.dirty || proeftrainingen.touched || formSubmitted)"
                class="error">
                De keuze is verplicht.
              </div>
            </label>
          </div>
          <div class="form-text">Nog geen proeftrainingen gehad, dan is inschrijven niet nodig. Je mag bij Black Shots
            twee keer gratis komen meetrainen. Kijk <a href="#/info?q=Proeftraining">hier</a> voor meer informatie.
          </div>

          <div class="form-sub-group">
            <label>Opmerkingen:
              <textarea placeholder="opmerkingen" opmerkingen name="opmerkingen" ngModel
                #opmerkingen="ngModel"></textarea>
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="form-group-header">Bank-gegevens</div>
          <div class="form-text">Als de contributie via stichting leergeld gaat, dan dat aangeven in het opmerkingenveld
            hierboven en graag onderstaande invullen.</div>
          <div class="form-sub-group">
            <label>IBAN: <span class="form-req-symbol"></span>
              <input type="text" placeholder="NL00XXXX1234567890" iban name="iban" ngModel required appIbanValidator
                pattern="\b(NL|nl)[0-9]{2}(?:[ ]?[a-zA-Z]{4})(?:[ ]?[0-9]{4}){2}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b"
                #iban="ngModel" [ngClass]='{ "invalid": iban.invalid && (iban.dirty || iban.touched || formSubmitted) }'
                oninput="this.value = this.value.toUpperCase()">
              <div *ngIf="iban.invalid && (iban.dirty || iban.touched || formSubmitted)" class="error">
                <div *ngIf="iban.errors.required">
                  IBAN is verplicht.
                </div>
                <div *ngIf="iban.errors.pattern">
                  IBAN heeft niet het juiste formaat.
                </div>
                <div *ngIf="!iban.errors.pattern && iban.errors.appIbanFout">
                  Geen geldig IBAN.
                </div>
              </div>
            </label>

            <label class="input-wide">Ten name van: <span class="form-req-symbol"></span>
              <input type="text" placeholder="Ten name van" tnv name="tnv" ngModel required #tnv="ngModel"
                [ngClass]='{ "invalid": tnv.invalid && (tnv.dirty || tnv.touched || formSubmitted) }'>
              <div *ngIf="tnv.invalid && (tnv.dirty || tnv.touched || formSubmitted)" class="error">
                <div *ngIf="tnv.errors.required">
                  Ten name van is verplicht.
                </div>
              </div>
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="form-group-header">Voorwaarden</div>
          <p>Ik geef de vereniging toestemming voor de hieronder aangevinkte gegevensverwerkingen (aanvinken wat van
            toepassing is). Toestemming voor deze aangevinkte gegevensverwerkingen kan op elk moment opgezegd worden.
          </p>

          <div class="input-group">
            <input type="checkbox" id="akkoordClub" name="akkoordClub" ngModel required #akkoordClub="ngModel"
              value="akkoordClub">
            <label for="akkoordClub" class="chck">Ik geef toestemming voor het vastleggen en verwerken van mijn gegevens
              voor het basketballen<span class="form-req-symbol"></span></label>
          </div>
          <div *ngIf="akkoordClub.invalid && (akkoordClub.dirty || akkoordClub.touched || formSubmitted)" class="error">
            Hiervoor is akkoord verplicht.
          </div>

          <div class="input-group">
            <input type="checkbox" id="akkoordPrivacy" name="akkoordPrivacy" ngModel #opmerkingen="ngModel"
              value="akkoordPrivacy">
            <label for="akkoordPrivacy" class="chck">Ik ga akkoord met het privacy beleid van de organisatie zoals
              omschreven op <a href="https://www.blackshots.nl/bs/#/info?q=Privacy">onze privacy pagina</a></label>
          </div>

          <div class="input-group">
            <input type="checkbox" id="akkoordFoto" name="akkoordFoto" ngModel #akkoordFoto="ngModel"
              value="akkoordFoto">
            <label for="akkoordFoto" class="chck">Ik geef toestemming voor het maken van foto’s tijdens wedstrijden,
              trainingen en activiteiten en het gebruik hiervan op onze website en sociale media</label>
          </div>

          <div class="input-group">
            <input type="checkbox" id="akkoordNAW" name="akkoordNAW" ngModel #akkoordNAW="ngModel" value="akkoordNAW">
            <label for="akkoordNAW" class="chck">Ik geef toestemming voor het delen van NAW gegevens met trainers,
              coaches
              en teamgenoten</label>
          </div>
        </div>

        <div class="form-group">
          <p>Door aanvinken van de onderstaande checkbox word je lid van Black Shots. Tevens ga je akkoord met het
            automatische afschrijven van de contributie van het hierboven vermelde bankrekeningnummer. Ook ga je akkoord
            met bijkomende taken (scheidsrechter- en tafeltaken) voor competitie spelende leden.</p>

          <div class="input-group">
            <input type="checkbox" id="akkoordLidAfschrijving" name="akkoordLidAfschrijving" ngModel
              #akkoordLidAfschrijving="ngModel" required value="akkoordLidAfschrijving">
            <label for="akkoordLidAfschrijving" class="chck">Ik ga akkoord met bovenstaande voorwaarden</label>
          </div>
          <div
            *ngIf="akkoordLidAfschrijving.invalid && (akkoordLidAfschrijving.dirty || akkoordLidAfschrijving.touched || formSubmitted)"
            class="error">Hiervoor is akkoord verplicht.</div>
        </div>

        <div [ngClass]='{ "invalid": contactForm.form.status === "INVALID" }'>
          <div *ngIf="contactForm.form.status === 'INVALID'" class="error">Het formulier is nog niet volledig ingevuld.
          </div>
          <input type="submit" value="Versturen" [disabled]="formSubmitted">
        </div>
      </ng-container>

      <div *ngIf="submitSucces" class="form-succes">Het inschrijfformulier is succesvol verstuurd. Je ontvangt nog een
        e-mail van de ledenadministratie als het formulier verwerkt is. Je kunt al direct starten met de training.</div>
    </form>
  </div>

  <div id="uit" [ngClass]="(option === 'Uitschrijven') ? 'show' : 'showMobile'">
    <div class="title">Uitschrijven</div>
    <p>Om je uit te schrijven als lid van Black Shots, kun je een e-mail sturen naar <a
        href="mailto:ledenadministratie@blackshots.nl?subject=Uitschrijven">ledenadministratie&#64;blackshots.nl</a>.
      Eventuele opmerkingen en aanbevelingen zijn natuurlijk van harte welkom.</p>
  </div>

  <div id="bestuur" [ngClass]="(option === 'Bestuur') ? 'show' : 'showMobile'">
    <div class="title">Bestuur</div>
    <p *ngIf="(data$ | async)">Het bestuur van Black Shots bestaat uit minimaal drie leden, te weten een voorzitter, een
      secretaris en een penningmeester. Het bestuur is bevoegd om onder zijn verantwoordelijkheid bepaalde onderdelen
      van zijn taak te laten uitvoeren door commissies. Het huidige bestuur bestaat uit {{bestuur?.length}}
      bestuursleden.</p>
    <div *ngIf="(data$ | async)" class="table-container col-sw" role="table">
      <div class="flex-table header" role="rowgroup">
        <div class="flex-row first" role="columnheader">Wie</div>
        <div class="flex-row last" role="columnheader">Omschrijving</div>
      </div>
      <div *ngFor="let b of bestuur" class="flex-table row" role="rowgroup">
        <div class="flex-row first" role="cell">{{b.functie}}<br>{{b.naam}}<br>{{b.telefoon}}<br>{{b.email}}</div>
        <div class="flex-row last" role="cell">{{b.omschrijving}}</div>
      </div>
    </div>
  </div>

  <div id="cies" [ngClass]="(option === 'Commissies') ? 'show' : 'showMobile'">
    <div class="title">Commissies</div>
    <p>Naast het bestuur van Black Shots zijn er ook een aantal commissies. Deze vallen allen onder minimaal één
      bestuurslid.</p>
    <div *ngIf="(data$ | async)" class="table-container col-sw" role="table">
      <div class="flex-table header" role="rowgroup">
        <div class="flex-row first" role="columnheader">Wie</div>
        <div class="flex-row last" role="columnheader">Omschrijving</div>
      </div>
      <div *ngFor="let b of commissies" class="flex-table row" role="rowgroup">
        <div class="flex-row first" role="cell" [innerHTML]=geefGegevens(b)></div>
        <div class="flex-row last" role="cell">{{b.omschrijving}}</div>
      </div>
    </div>
  </div>

  <div id="socials" class="social showMobile">
    <a href="mailto:info@blackshots.nl" target="_blank" class="btn bd-ra" title="Stuur e-mail"><span
        class="fa fa-envelope-o"></span></a>
    <a href="http://www.facebook.com/pages/Basketbalvereniging-Black-Shots/103597959807970" target="_blank"
      class="btn bd-ra" title="Facebook"><span class="fa fa-facebook"></span></a>
    <a href="http://instagram.com/blackshotsbasketball" target="_blank" class="btn bd-ra" title="Instagram"><span
        class="fa fa-instagram"></span></a>
    <a href="http://twitter.com/black__shots" target="_blank" class="btn bd-ra" title="Twitter"><span
        class="fa fa-twitter"></span></a>
  </div>

  <ng-container right>
    <ng-container>
      <div [@listStagger]='options.length' class="right-options">
        <div *ngFor='let opt of options' class="option" [ngClass]='{ "active": opt === option}'
          (click)="changeOption(opt)">{{opt}}</div>
      </div>
    </ng-container>
  </ng-container>
</app-contentpage>
