import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BasePaginaDirective } from '../../directives/base/base-pagina.component';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClubComponent extends BasePaginaDirective implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() { }

  scrollToElement(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
