<app-contentpage [pagina]="'club'" [image]="getImageUrl('yellow.jpg')">
  <div class="showMobile links">
    <a (click)="scrollToElement('reglement')">Reglement</a>
    <a (click)="scrollToElement('alv')">Algemene Leden Vergadering</a>
  </div>

  <h2>Ontstaan</h2>
  <p>Black Shots is notarieel opgericht op 11 maart 2011 onder de naam Basketball Brandevoort door Roy Boermeester en
    Erik van Eert. De eerste proeftraining is van start gegaan op 21 maart 2011 en op maandag 4 april 2011 was de
    eerste officiële training. Het doel van Black Shots is om op een ongedwongen wijze een optimale balans te vinden
    tussen sportieve prestaties op het gebied van basketball en een gezellig clubleven. Daarnaast streeft Black
    Shots ernaar de continuïteit binnen de club te handhaven. Dat wil zeggen: voldoende instroom en handhaving van
    de (jeugd)leden door het organiseren van allerlei sfeerverhogende activiteiten.</p>
  <p>De uitdagingen van Black Shots bestaan uit:</p>
  <ul>
    <li>Voldoende trainers en coaches.</li>
    <li>Zaalfaciliteiten voor wedstrijden, trainingen en extra clinics.</li>
    <li>Waarborgen continuïteit van de jeugd en de club in het algemeen en meisjes- en damesbasketball in het
      bijzonder.</li>
  </ul>

  <h2 id="reglement">Reglement</h2>
  <p>In het Huishoudelijk Reglement is de regelgeving weergegeven met betrekking tot de dagelijkse gang van zaken en
    interne aangelegenheden binnen Black Shots. Het beschrijft rechten en plichten, niet alleen van de leden maar
    ook van het bestuur en de verschillende commissies. Het Huishoudelijk Reglement is ondergeschikt aan de statuten
    van de vereniging. Dit wil zeggen dat de statuten gehanteerd worden, zodra het Huishoudelijk Reglement daarmee
    in tegenspraak is. Ieder lid wordt geacht de statuten en het Huishoudelijk Reglement te kennen en deze na te
    leven. <a href="./assets/docs/HuishoudelijkReglement.pdf" target="_blank">Download het hier.</a></p>

  <h2 id="alv">Algemene Leden Vergadering</h2>
  <p>De Algemene Leden Vergadering wordt gehouden in oktober, tijd, locatie en agenda worden minimaal twee weken van
    tevoren aan alle leden bekend gemaakt. Tijdens de ALV dient het Bestuur verantwoordelijkheid af te leggen voor
    al haar (financiële) daden. Ook belangrijke veranderingen binnen de vereniging dienen aan bod te komen, zoals
    contributiewijzigingen, eventuele bestuursveranderingen, enzovoort. Tijdens de ALV brengt de secretaris het
    jaarverslag ten gehore en er vindt rapportage plaats van alle andere commissies. ook de kascommissie brengt
    tijdens de ALV verslag over het gevoerde financiële beleid. Tijdens de ALV wordt ook de kascommissie opnieuw
    gekozen.</p>

  <ng-container right>
    <a href='#/bbc'><img class="imgbbc" src="./assets/images/bbc.png"></a>
    <div class="tickets">Kaartjes voor Heroes Den Bosch, scan onderstaande code.<br><br><img src="./assets/images/qrconvenant.jpg"></div>
  </ng-container>
</app-contentpage>
