import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgModel } from '@angular/forms';
import { Vraag } from '../../../app/models/vraag';

@Component({
  selector: 'app-speurtocht',
  templateUrl: './speurtocht.component.html',
  styleUrls: ['./speurtocht.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpeurtochtComponent implements OnInit {
  count = 0;
  @ViewChild('antwoord', { static: false }) antwoordInput: ElementRef;

  countLaatste = 50;
  speurtocht: string;
  antwoord: string;
  antwoordGoed = false;
  vragen: Vraag[];

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.vragen = [];

    this.activatedRoute.queryParams.subscribe(params => {
      this.speurtocht = params['s'];

      if (this.speurtocht === '1') {
        this.vragen = [
          { id: 1, tekst: "Ga door de poort en ga naar rechts", vraagStellen: false, vraag: "Loop over het zandpad tot de splitsing", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 2, tekst: "", vraagStellen: true, vraag: "Hoeveel is 4 * 3 * 4 - 20 + 4?", image: "rekenen.png", antwoord: "32", placeholder: "Vul het getal in" },
          { id: 3, tekst: "Helemaal goed", vraagStellen: false, vraag: "Loop die richting in.", image: "foto3.jpg", antwoord: "", placeholder: "" },
          { id: 4, tekst: "Zoek deze foto", vraagStellen: false, vraag: "Loop het pad in tot een T-splitsing", image: "foto4.jpg", antwoord: "", placeholder: "" },
          { id: 5, tekst: "Wat is voornaam van de NBA basketballer die in 2020 bij een helikopterongeluk om het leven kwam?", vraagStellen: true, vraag: "", image: "nba.jpg", antwoord: "kobe", placeholder: "Vul de voornaam in" },
          { id: 6, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 7, tekst: "Wat moet het worden?", vraagStellen: true, vraag: "", image: "cijfercode.png", antwoord: "518", placeholder: "Vul het getal in" },
          { id: 8, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 9, tekst: "Zoek deze foto", vraagStellen: false, vraag: "En onthoud aan welke kant van het pad deze ligt", image: "foto5.jpg", antwoord: "", placeholder: "" },
          { id: 10, tekst: "Zoek deze foto", vraagStellen: false, vraag: "Loop die richting in totdat je een mountainbike pad gepasseerd bent", image: "prullenbak.jpg", antwoord: "", placeholder: "" },
          { id: 11, tekst: "Zoek daarna deze foto", vraagStellen: true, vraag: "In de bomen op de heuvel links hangen 8 letters. Probeer ze maar te vinden.", image: "stenenletters.jpg", antwoord: "abgkmqtx", placeholder: "8 letters op alfabet" },
          { id: 12, tekst: "Helemaal goed", vraagStellen: false, vraag: "Loop terug en steek het pad over.", image: "logo_sec.jpg", antwoord: "", placeholder: "" },
          { id: 13, tekst: "Aan welke kant lag die boom ook alweer?", vraagStellen: false, vraag: "Ga die kant op.", image: "rechtslinks.jpg", antwoord: "", placeholder: "" },
          { id: 14, tekst: "Zoek deze foto", vraagStellen: false, vraag: "Ga hier rechtsaf", image: "foto14.jpg", antwoord: "", placeholder: "" },
          { id: 15, tekst: "", vraagStellen: true, vraag: "Wie is het oudste lid?", image: "logo_sec.jpg", antwoord: "Marijn", placeholder: "" },
          { id: 16, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 17, tekst: "Anagram", vraagStellen: true, vraag: "<br><br>Welke woord of woorden kunnen jullie maken van:<br><br>SABBEL KENTAL<br><br>", image: "", antwoord: "basketballen", placeholder: "Vul hier de oplossing" },
          { id: 18, tekst: "Helemaal goed", vraagStellen: false, vraag: "Loop door tot het volgende kruispunt", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 19, tekst: "Dit is een voorbeeld van een droedel", vraagStellen: false, vraag: "De E staat in D en daarna volgt een punt, antwoord is dus<br>E in D punt:<br>eindpunt", image: "eindpunt.jpg", antwoord: "", placeholder: "" },
          { id: 20, tekst: "Wat is de uitkomst van deze droedel?", vraagStellen: true, vraag: "Zie je de oplossing?", image: "droedel1.jpg", antwoord: "nachtblind", placeholder: "Vul hier het woord" },
          { id: 21, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 22, tekst: "Loop door tot onderstaande foto", vraagStellen: false, vraag: "Ga daar rechtsaf", image: "stenen.jpg", antwoord: "", placeholder: "" },
          { id: 23, tekst: "", vraagStellen: true, vraag: "Ontcijfer onderstaande code<br><img src='./assets/images/speur/geheimschriftopdracht.png'>", image: "geheimschrift.jpeg", antwoord: "goed gedaan", placeholder: "Wat staat er?" },
          { id: 24, tekst: "Helemaal goed", vraagStellen: false, vraag: "Steek 2 keer een mountainbike pad over.", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 25, tekst: "Zet deze spelers op de juiste leeftijd van jong naar oud", vraagStellen: true, vraag: "Carl, Evi, Guus, Jelle, Lotte, Thom<br><br>Let op de 1e letter van de naam volstaat!<br>", image: "", antwoord: "ETJLCG", placeholder: "Alleen 1e letters" },
          { id: 26, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 27, tekst: "Zoek deze foto", vraagStellen: false, vraag: "En ga het pad in", image: "foto27.jpg", antwoord: "", placeholder: "" },
          { id: 28, tekst: "Wie werd in 2021 na 50 jaar weer kampioen in de NBA?", vraagStellen: true, vraag: "", image: "nba.jpg", antwoord: "Milwaukee Bucks", placeholder: "" },
          { id: 29, tekst: "Helemaal goed", vraagStellen: false, vraag: "doorlopen tot de 5-sprong", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 30, tekst: "De bovenkant van de foto is het noorden", vraagStellen: false, vraag: "Ga dan in NNW-richting", image: "foto30.jpg", antwoord: "", placeholder: "" },
          { id: 31, tekst: "Wie behalve Erik heeft Black Shots opgericht?", vraagStellen: true, vraag: "We zoeken de achternaam", image: "logo_sec.jpg", antwoord: "Boermeester", placeholder: "Achternaam" },
          { id: 32, tekst: "Zoek deze foto", vraagStellen: false, vraag: "", image: "stronk.jpg", antwoord: "", placeholder: "" },
          { id: 33, tekst: "Wat is de uitkomst van deze droedel?", vraagStellen: true, vraag: "Welke sport zoeken we?", image: "droedel2.jpg", antwoord: "tennis", placeholder: "Vul hier het woord" },
          { id: 34, tekst: "Helemaal goed, het pad van de foto niet inlopen. Maar verderop", vraagStellen: false, vraag: "", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 35, tekst: "Letterraadsel", vraagStellen: true, vraag: "Raad de volgende drie letters in deze serie<br>", image: "letterraadsel.jpg", antwoord: "ids", placeholder: "Vul 3 letters" },
          { id: 36, tekst: "Helemaal goed", vraagStellen: false, vraag: "Jullie zijn er bijna. Loop nog een paar honderd meter rechtdoor en dan...", image: "rechtsaf.png", antwoord: "", placeholder: "" },
        ]
      }
      if (this.speurtocht === '2') {
        this.vragen = [
          { id: 1, tekst: "Ga door de poort en ga naar rechts", vraagStellen: false, vraag: "Loop over het zandpad tot de splitsing", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 2, tekst: "", vraagStellen: true, vraag: "Hoeveel is 4 * 3 * 4 - 14 + 4?", image: "rekenen.png", antwoord: "38", placeholder: "Vul het getal in" },
          { id: 3, tekst: "Helemaal goed", vraagStellen: false, vraag: "Loop die richting in.", image: "foto_3.jpg", antwoord: "", placeholder: "" },
          { id: 4, tekst: "Zoek deze foto", vraagStellen: false, vraag: "Loop het pad in", image: "foto_4.jpg", antwoord: "", placeholder: "" },
          { id: 5, tekst: "Wat is voornaam van de NBA basketballer die in 2020 bij een helikopterongeluk om het leven kwam?", vraagStellen: true, vraag: "", image: "logo_sec.jpg", antwoord: "kobe", placeholder: "Vul de voornaam in" },
          { id: 6, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 7, tekst: "Zoek deze foto", vraagStellen: false, vraag: "", image: "stronk.jpg", antwoord: "", placeholder: "" },
          { id: 8, tekst: "", vraagStellen: true, vraag: "Ontcijfer onderstaande code<br><img src='./assets/images/speur/geheimschriftopdracht.png'>", image: "geheimschrift.jpeg", antwoord: "goed gedaan", placeholder: "Wat staat er?" },
          { id: 9, tekst: "Helemaal goed, het pad van de foto niet inlopen.", vraagStellen: false, vraag: "", image: "stop.jpg", antwoord: "", placeholder: "" },
          { id: 10, tekst: "Zoek deze foto", vraagStellen: false, vraag: "Loop dit pad in en ga rechtdoor bij het kleine paadje links", image: "foto_7.jpg", antwoord: "", placeholder: "" },
          { id: 11, tekst: "Wat moet het worden?", vraagStellen: true, vraag: "", image: "cijfercode.png", antwoord: "518", placeholder: "Vul het getal in" },
          { id: 12, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 13, tekst: "Zoek deze foto", vraagStellen: false, vraag: "Ga hier linksaf", image: "foto_10.jpg", antwoord: "", placeholder: "" },
          { id: 14, tekst: "", vraagStellen: true, vraag: "Wie is het oudste lid?", image: "logo_sec.jpg", antwoord: "Marijn", placeholder: "" },
          { id: 15, tekst: "Helemaal goed", vraagStellen: false, vraag: "Steek verderop 2 keer een mountainbike pad over", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 16, tekst: "Anagram", vraagStellen: true, vraag: "<br><br>Welke woord of woorden kunnen jullie maken van:<br><br>SABBEL KENTAL<br><br>", image: "", antwoord: "basketballen", placeholder: "Vul hier de oplossing" },
          { id: 17, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 18, tekst: "Loop door tot onderstaande foto", vraagStellen: false, vraag: "Ga daar linksaf", image: "stenen.jpg", antwoord: "", placeholder: "" },
          { id: 19, tekst: "Dit is een voorbeeld van een droedel", vraagStellen: false, vraag: "De E staat in D en daarna volgt een punt, antwoord is dus<br>E in D punt:<br>eindpunt", image: "eindpunt.jpg", antwoord: "", placeholder: "" },
          { id: 20, tekst: "Wat is de uitkomst van deze droedel?", vraagStellen: true, vraag: "Zie je de oplossing?", image: "droedel1.jpg", antwoord: "nachtblind", placeholder: "Vul hier het woord" },
          { id: 21, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 22, tekst: "Helemaal goed", vraagStellen: false, vraag: "Ga het volgende pad rechtsaf", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 23, tekst: "Zet deze spelers op de juiste leeftijd van jong naar oud", vraagStellen: true, vraag: "Carl, Evi, Guus, Jelle, Lotte, Thom<br><br>Let op de 1e letter van de naam volstaat!<br>", image: "", antwoord: "ETJLCG", placeholder: "Alleen 1e letters" },
          { id: 24, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 25, tekst: "Wie werd in 2021 na 50 jaar weer kampioen in de NBA?", vraagStellen: true, vraag: "", image: "nba.jpg", antwoord: "Milwaukee Bucks", placeholder: "" },
          { id: 26, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 27, tekst: "De bovenkant van de foto is het noorden", vraagStellen: false, vraag: "Ga dan in ONO-richting", image: "foto_25.jpg", antwoord: "", placeholder: "" },
          { id: 28, tekst: "Zoek daarna deze foto", vraagStellen: true, vraag: "In de bomen op de heuvel voor jullie hangen 8 letters. Probeer ze maar te vinden.", image: "stenenletters.jpg", antwoord: "abgkmqtx", placeholder: "8 letters op alfabet" },
          { id: 29, tekst: "Helemaal goed. Loop terug naar het pad en ga rechts.", vraagStellen: false, vraag: "", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 30, tekst: "Zoek deze foto", vraagStellen: false, vraag: "En ga daarna linksaf het pad in", image: "prullenbak.jpg", antwoord: "", placeholder: "" },
          { id: 31, tekst: "Zoek deze foto", vraagStellen: false, vraag: "En onthoud aan welke kant van het pad deze ligt", image: "foto5.jpg", antwoord: "", placeholder: "" },
          { id: 32, tekst: "Letterraadsel", vraagStellen: true, vraag: "Raad de volgende drie letters in deze serie<br>", image: "letterraadsel.jpg", antwoord: "ids", placeholder: "Vul 3 letters" },
          { id: 33, tekst: "Helemaal goed", vraagStellen: false, vraag: "", image: "rechtsaf.png", antwoord: "", placeholder: "" },
          { id: 34, tekst: "Wie behalve Erik heeft Black Shots opgericht?", vraagStellen: true, vraag: "We zoeken de achternaam", image: "logo_sec.jpg", antwoord: "Boermeester", placeholder: "Achternaam" },
          { id: 35, tekst: "Helemaal goed", vraagStellen: false, vraag: "Ga rechtdoor bij het kleine paadje links", image: "linksaf.png", antwoord: "", placeholder: "" },
          { id: 36, tekst: "Aan welke kant lag die boom ook alweer?", vraagStellen: false, vraag: "Ga die kant op.", image: "rechtslinks.jpg", antwoord: "", placeholder: "" },
          { id: 37, tekst: "Hier linksaf", vraagStellen: false, vraag: "Jullie zijn er bijna. Loop nog een paar honderd meter rechtdoor en dan...", image: "linksaf.png", antwoord: "", placeholder: "" }
        ]
      }
    });
    this.countLaatste = this.vragen.length + 1;

    const tokenCount = localStorage.getItem("tokenCount");
    if (tokenCount) {
      this.count = Number(tokenCount);
    }
  }

  clickVolgende(vraag: Vraag) {
    if (this.count === 0 || this.volgendeOk(vraag)) {
      this.count++;
      localStorage.setItem("tokenCount", this.count.toString());
    }
  }

  volgendeOk(vraag: Vraag) {
    if (vraag.vraagStellen) {
      if (this.antwoord && this.antwoord.length > 0 && vraag.antwoord.toLowerCase().replace(/\s/g, '') === this.antwoord.toLowerCase().replace(/\s/g, '')) {
        this.antwoordInput.nativeElement.value = '';
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  clickTerug() {
    this.count--;
    localStorage.setItem("tokenCount", this.count.toString());
  }

  onKey(event: KeyboardEvent) {
    this.antwoord = (event.target as HTMLInputElement).value
  }
}
