<app-contentpage [pagina]="'news'" [image]="getImageUrl('aqua.jpg')">
  <div *ngIf="(data$ | async) as d">
    <div class="title">Agenda</div>
    <div class="table-container cols2" role="table">
      <div class="flex-table header" role="rowgroup">
        <div class="flex-row" role="columnheader">Datum</div>
        <div class="flex-row" role="columnheader">Activiteit</div>
      </div>
      <div *ngFor="let a of d.agenda" class="flex-table row" role="rowgroup">
        <div class="flex-row" role="cell">{{a.datum}}</div>
        <div class="flex-row" role="cell">{{a.activiteit}}</div>
      </div>
    </div>
  </div>

  <div *ngIf="(news$ | async)">
    <div class="title">Nieuws</div>
    <div *ngFor="let a of artikelen" class="article">
        <img src="./assets/images/news/{{a.image}}">
        <div class="article-content">
          <div class="header">{{a.kop}}</div>
          <div class="datum">{{a.datum}}</div>
          <div class="intro">{{a.inleiding}}</div>
          <div class="text" [innerHTML]="a.tekst"></div>
        </div>
    </div>
  </div>

  <ng-container right>
    <a href='#/bbc'><img class="imgbbc" src="./assets/images/bbc.png"></a>
  </ng-container>
</app-contentpage>