<app-contentpage [pagina]="'tools'">
  <div class="content-tools">
    <h2>Zet hier je e-mailadressen</h2>
    <textarea #mailinput></textarea>
    <h2 class="inline">Aantal e-mailadressen per groep</h2>
    <input type="text" [(ngModel)]="aantalPerGroep">
    <div class="card-container">
      <div class="card" (click)="ontdubbelen(mailinput.value)">
        <span class="material-icons"><span>reorder</span></span>
        <span>Adressen ontdubbelen</span>
      </div>
    </div>

    <ng-container *ngIf="mailresult && mailresult.length > 0">
      <h2>Aantal e-mailadressen van {{aantalAdressenVooraf}} naar {{aantalAdressen}}</h2>
      <div class="card-container">
        <div *ngFor="let adressen of mailresult; let i = index" class="card" (click)="copyToClipboard(adressen, i)"  [ngClass]="{'copied': isClicked(i) }">
          <span class="material-icons"><span>download</span></span>
          <span>{{geefCopyText(i)}}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container right></ng-container>
</app-contentpage>
