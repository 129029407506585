import { Directive } from '@angular/core';
import { environment } from '@env/environment';

@Directive()
export class BasePaginaDirective {
    constructor() { }

    getImageUrl(image: string): string {
        return environment.imageLocation + image;
    }
}