<app-contentpage [pagina]="'teams'" [image]="getImageUrl('spelersvlakken.jpg')">
  <div *ngIf="(data$ | async) as d" class="showMobile links">
    <a *ngFor='let t of d.teams' (click)="scrollToElement(t.menutitel)">{{t.naam}}</a>
  </div>

  <div *ngIf="(data$ | async) as d">
    <div *ngFor='let t of d.teams' id="{{t.menutitel}}" class="team" [ngClass]='{ "show": t.naam === currentTeamnaam}'>
      <div class="title">{{t.naam}}</div>
      <div *ngIf="t.teaminfo" class="team-info" [innerHTML]="t.teaminfo"></div>
      <div *ngIf="t.coach" class="team-pair">
        <div class="team-key">Coach:</div>
        <div class="team-val">{{t.coach}}</div>
      </div>
      <div *ngIf="t.trainer" class="team-pair">
        <div class="team-key">Trainer:</div>
        <div class="team-val" [innerHTML]="t.trainer"></div>
      </div>
      <div *ngFor="let tr of t.trainingen" class="team-pair">
        <div class="team-key">Training:</div>
        <div class="team-val">
          {{tr.dag}}<br>{{tr.tijd}}<br>{{tr.zaal}}
        </div>
      </div>
      <div *ngIf="t.spelers" class="team-pair">
        <div class="team-key">Spelers:</div>
        <div class="team-val">
          <div *ngFor="let s of t.spelers" [innerHTML]="s.naam"></div>
        </div>
      </div>
      <div class="stand" *ngIf="t.stand">
        <div class="stand-title">Stand</div>
        <div>
          <div class="stand-rank">#</div>
          <div class="stand-team">Team</div>
          <div class="stand-gesp tooltip">G<span class="tooltiptext">Gespeeld</span></div>
          <div class="stand-punt tooltip">P<span class="tooltiptext">Punten</span></div>
          <div class="stand-vt tooltip">V-T<span class="tooltiptext">Scores voor en tegen</span></div>
        </div>
        <div *ngFor="let s of t.stand">
          <div class="stand-rank">{{s.rang}}</div>
          <div class="stand-team">{{s.team}}</div>
          <div class="stand-gesp">{{s.gespeeld}}</div>
          <div class="stand-punt">{{s.punten}}</div>
          <div class="stand-vt">{{s.eigenscore}}-{{s.tegenscore}}</div>
        </div>
      </div>

      <ng-container *ngIf="t.openWedstrijden?.length > 0">
        <div class="poule-title">
          <div>{{wedstrijdTitel}}</div>
          <div (click)="toggleWedstrijden()" class="button toggle">
            <span>{{toggleWedstrijdNaam}}</span>
            <i *ngIf="toggleAlleWedstrijden" class="fa fa-chevron-up"></i>
            <i *ngIf="!toggleAlleWedstrijden" class="fa fa-chevron-down"></i>
          </div>
        </div>
        <div class="table-container col-wed" role="table">
          <div class="flex-table header" role="rowgroup">
            <div class="flex-row first" role="columnheader">Datum</div>
            <div class="flex-row" role="columnheader">Thuis</div>
            <div class="flex-row" role="columnheader">Uit</div>
            <div class="flex-row" role="columnheader">Hal</div>
            <div class="flex-row last" role="columnheader">Plaats</div>
          </div>
          <div *ngFor="let w of geefWedstrijden(t.openWedstrijden)" class="flex-table row" role="rowgroup">
            <div class="flex-row first" role="cell">{{w.datum | date:'dd-MM-yyyy H:mm'}}</div>
            <div class="flex-row" role="cell">{{w.thuis_ploeg}}</div>
            <div class="flex-row" role="cell">{{w.uit_ploeg}}</div>
            <div class="flex-row" role="cell">{{w.loc_naam}}</div>
            <div class="flex-row last" role="cell">{{w.loc_plaats}}</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="t.uitslagen?.length > 0">
        <div class="poule-title">
          <div>{{uitslagTitel}}</div>
          <div (click)="toggleUitslagen()" class="button toggle">
            <span>{{toggleUitslagNaam}}</span>
            <i *ngIf="toggleAlleUitslagen" class="fa fa-chevron-up"></i>
            <i *ngIf="!toggleAlleUitslagen" class="fa fa-chevron-down"></i>
          </div>
        </div>
        <div class="table-container col-uitslag" role="table">
          <div class="flex-table header" role="rowgroup">
            <div class="flex-row first" role="columnheader">Datum</div>
            <div class="flex-row" role="columnheader">Thuis</div>
            <div class="flex-row" role="columnheader">Uit</div>
            <div class="flex-row" role="columnheader">Plaats</div>
            <div class="flex-row last" role="columnheader">Uitslag</div>
          </div>
          <div *ngFor="let w of geefUitslagen(t.uitslagen)" class="flex-table row" role="rowgroup">
            <div class="flex-row first" role="cell">{{w.datum | date:'dd-MM-yyyy H:mm'}}</div>
            <div class="flex-row" role="cell">{{w.thuis_ploeg}}</div>
            <div class="flex-row" role="cell">{{w.uit_ploeg}}</div>
            <div class="flex-row" role="cell">{{w.loc_plaats}}</div>
            <div class="flex-row last" role="cell">{{w.score_thuis}}-{{w.score_uit}}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container right>
    <ng-container *ngIf='data$ | async as d'>
      <div [@listStagger]='d.length' class="right-options">
        <div *ngFor='let t of d.teams' class="option" [ngClass]='{ "active": t.naam === currentTeamnaam}'
          (click)="changeTeam(t.naam)">{{t.naam}}</div>
      </div>
    </ng-container>
  </ng-container>
</app-contentpage>
