import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, style, animate, transition, query, stagger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { DataService } from '../../../app/services/data.service';
import { Data } from '../../../app/models/data';
import { Contributierec } from '../../models/contributierec';
import { Contributiewed } from '../../models/contributiewed';
import { Leeftijden } from '../../models/leeftijden';
import { Geentraining } from '../../models/geentraining';
import { BasePaginaDirective } from '../../directives/base/base-pagina.component';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  animations: [
    trigger('listStagger', [
      transition('* <=> *', [
        query(':enter',
          [
            style({ opacity: 0, transform: 'translateY(-100px)' }),
            stagger('50ms', animate('750ms ease-out', style({ opacity: 1, transform: 'translateY(0px)' })))
          ],
          { optional: true }
        ),
        query(':leave', animate('50ms', style({ opacity: 0 })), {
          optional: true
        })
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class InfoComponent extends BasePaginaDirective implements OnInit {
  data$: Observable<Data>;
  option: string;
  hashkey: string;
  options: string[] = [];
  contributierec: Contributierec[];
  contributiewed: Contributiewed[];
  geentrainingen: Geentraining[];
  geentrainingkop: string;
  geentrainingdatums: string;
  leeftijden: Leeftijden[];
  seizoen: string;
  sticky: boolean;

  constructor(
    private _dataService: DataService,
    private _route: ActivatedRoute
  ) { super(); }

  ngOnInit() {
    this.data$ = this._dataService.data;
    this.options.push('Proeftraining');
    this.options.push('Contributie');
    this.options.push('Leeftijdsindeling');
    this.options.push('Tenue');
    this.options.push('Coaches - wedstrijden');
    this.options.push('Trainers - trainingen');
    this.options.push('Fluiten en tafelen');
    this.options.push('Clubsupport');
    this.options.push('Hallen');
    this.options.push('Gedragsregels');
    this.options.push('Privacy');
    this.options.push('Sportlink');

    this.hashkey = this._route.snapshot.queryParams['q'];
    if (this.hashkey !== undefined && this.options.indexOf(this.hashkey) > -1) {
      this.option = this.hashkey;
    } else {
      this.option = this.options[0];
    }

    this.data$.subscribe(d => {
      if (d !== undefined && d !== null) {
        this.contributierec = d.contributierec;
        this.contributiewed = d.contributiewed;
        this.leeftijden = d.leeftijden;
        this.seizoen = d.seizoen;
        this.geentrainingen = d.geentrainingen;
        this.geentrainingkop = d.geentrainingkop;

        this.geentrainingdatums = '';

        if (d.geentrainingen?.length > 0) {
          for (let index = 0; index < d.geentrainingen.length; index++) {
            if (index === 0) {
              this.geentrainingdatums += d.geentrainingen[index].datum;
            } else {
              if (index + 1 === d.geentrainingen.length) {
                this.geentrainingdatums += ' en ' + d.geentrainingen[index].datum;
              } else {
                this.geentrainingdatums += ', ' + d.geentrainingen[index].datum;
              }
            }
          }
        }
      }
    });
  }

  changeOption(optie: string) {
    this.option = optie;
  }

  scrollToElement(id: string): void {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
}
