import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent {
  isMobile: boolean;
  tile = 0;

  constructor(
    private _router: Router
  ) { }

  tileClick(tilenr: number, route: string): void {
    this.tile = tilenr;
    setTimeout(() => {
      this._router.navigate([route]);
    }, 2700);
  }

  classTile(tilenr: number): string {
    if (this.tile === 0) {
      return '';
    }
    if (this.tile === tilenr) {
      return 'click';
    }
    return 'gone' + tilenr;
  }
}
