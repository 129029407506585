import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { DataService } from '../../../app/services/data.service';
import { Faq } from '../../models/faq';
import { BasePaginaDirective } from '../../directives/base/base-pagina.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent extends BasePaginaDirective implements OnInit {
  faqs$: Observable<Faq>;

  constructor(
    private _dataService: DataService,
    private _renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    this.faqs$ = this._dataService.faqs;
  }

  clickVraag($event): void {
    if ($event.currentTarget == null) {
      return;
    }

    let element = $event.currentTarget;

    if (element.classList.contains('open')) {
      this._renderer.removeClass(element, 'open');
      this._renderer.addClass(element, 'closed');
    } else {
      this._renderer.removeClass(element, 'closed');
      this._renderer.addClass(element, 'open');
    }
  }
}
