import { Activiteit } from './activiteit';
import { Bestuur } from './bestuur';
import { Contributierec } from './contributierec';
import { Contributiewed } from './contributiewed';
import { Team } from './team';
import { Geentraining } from './geentraining';
import { Leeftijden } from './leeftijden';
import { Wedstrijd } from './wedstrijd';

export class Data {
    agenda: Activiteit[];
    bestuur: Bestuur[];
    commissies: Bestuur[];
    contributiewed: Contributiewed[];
    contributierec: Contributierec[];
    geentrainingkop: string;
    geentrainingen: Geentraining[];
    leeftijden: Leeftijden[];
    seizoen: string;
    teams: Team[];
    wedstrijden: Wedstrijd[];
}
