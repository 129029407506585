<app-contentpage [pagina]="'faq'" [image]="getImageUrl('aqua.jpg')">
  <h2>FAQ's</h2>

  <div *ngIf="(faqs$ | async) as fq">
    <div *ngFor='let f of fq.faqs'>
      <h3>{{f.faqrubriek}}</h3>
      <div *ngFor='let v of f.faq'>
        <div class="faq-container">
          <div class="faqvraag closed" (click)="clickVraag($event)">
            <span>{{v.faqvraag}}</span>
            <i class="fa fa-chevron-down"></i>
          </div>
          <div class="faqantw" [innerHTML]="v.faqantwoord"></div>
        </div>
      </div>
    </div>
  </div>

  <ng-container right>
    <a href='#/bbc'><img class="imgbbc" src="./assets/images/bbc.png"></a>
  </ng-container>
</app-contentpage>

<!-- <i class="fa fa-chevron-up"></i></div> -->
