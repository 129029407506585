export class News {
    nieuws: Newsarticle[];
}

export class Newsarticle {
    datum: string;
    kop: string;
    image: string;
    inleiding: string;
    tekst: string;
    publiceren: boolean;
}
