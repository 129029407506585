<app-contentpage [pagina]="'bbc'" [image]="getImageUrl('aqua.jpg')">
  <h2>BRABANTS BASKETBALL CONVENANT</h2>
  <p>
    Black Shots heeft een samenwerkingsovereenkomst met landelijke basketbalvereniging Heroes Basketball uit
    Den Bosch. Het doel is om samen basketbal naar een hoger niveau te tillen. Het door New Heroes Basketball
    geïnitieerde convenant verenigt basketbalverenigingen in de wijde omtrek van Brabant, om zo de krachten te bundelen.
    Deze samenwerking is gestoeld op de pijlers techniek, commercie en organisatie om zo de basketbalsport een boost te
    geven. In overleg met de verenigingen is een jaarprogramma opgesteld om op continue basis elkaar vooruit te helpen.
    Het convenant is een levend document. Dit betekent dat het convenant altijd open is voor aanpassingen en
    verbeteringen. Zo kunnen de deelnemende verenigingen optimaal van de samenwerking profiteren en kan er in het snel
    wisselende sportlandschap ingesprongen worden op de laatste ontwikkelingen. Om dit realiseren wordt er meerdere
    malen per jaar een voorzittersoverleg gehouden, waar alle partijen het convenant samen zullen evalueren. Onze
    ambitie is om met deze samenwerking het basketbal in Brabant op sporttechnisch, organisatorisch en commercieel vlak
    zichtbaar te zien groeien.
  </p>
  <p>
    Meer uitleg vind je <a href="https://heroesdenbosch.com/brabants-basketball-convenant">hier</a>.
  </p>
  <ng-container right>
    <img class="imgbbc" src="./assets/images/bbc.png">
  </ng-container>
</app-contentpage>
