import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { DataService } from '../../../app/services/data.service';
import { Data } from '../../../app/models/data';
import { News, Newsarticle } from '../../../app/models/news';
import { BasePaginaDirective } from '../../directives/base/base-pagina.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsComponent extends BasePaginaDirective implements OnInit {
  data$: Observable<Data>;
  news$: Observable<News>;
  artikelen: Newsarticle[];

  constructor(private _dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.data$ = this._dataService.data;
    this.news$ = this._dataService.news;
    this.news$.subscribe(n => {
      if (n !== undefined && n.nieuws && n.nieuws.length > 0) {
        this.artikelen = new Array<Newsarticle>();
        n.nieuws.forEach((a: Newsarticle) => {
          if (a.publiceren) {
            this.artikelen.push(a);
          }
        });
      }
    });
  }
}
