import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Subject, BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@env/environment';
import { Data } from '../models/data';
import { Faqs } from '../models/faqs';
import { News } from '../models/news';
import { Team } from '../models/team';
import { ResponseStand } from '../models/response-stand';
import { ResponseWedstrijden } from '../models/response-wedstrijden';

@Injectable()
export class DataService {
  private _data: Subject<Data> = new BehaviorSubject<Data>(new Data);
  public readonly data: Observable<Data> = this._data.asObservable();

  private _faqs: Subject<Faqs> = new BehaviorSubject<Faqs>(new Faqs);
  public readonly faqs: Observable<Faqs> = this._faqs.asObservable();

  private _news: Subject<News> = new BehaviorSubject<News>(new News);
  public readonly news: Observable<News> = this._news.asObservable();

  constructor(private http: HttpClient) { }

  getData() {
    const httpHeaders = new HttpHeaders().set('Accept', 'text/html,application/xhtml+xml');
    this.http.get(environment.dataUrl, { headers: httpHeaders, responseType: 'json' }).subscribe((response: Data) => {
      this._data.next(this.mapData(response));
    });
  }

  private mapData(response: Data): Data {
    if (response.teams) {
      const seizoen = response.seizoen;

      response.teams.forEach((t: Team) => {
        if (t.compid) {
          this.http.get(environment.dbstand + location.host + '&szn_Naam=' + seizoen + '&cmp_ID=' + t.compid)
            .subscribe((res: ResponseStand) => {
              t.stand = res.stand;
            });

          this.http.get(environment.dbwed + location.host + '&szn_Naam=' + seizoen + '&cmp_ID=' + t.compid)
            .subscribe((res: ResponseWedstrijden) => {
              t.uitslagen = res.wedstrijden.filter(w => w.score_thuis > 0 || w.score_uit > 0);
              t.openWedstrijden = res.wedstrijden.filter(w => !w.score_thuis || w.score_thuis === 0);
            });
        }
      });

      this.http.get(environment.dbwed + location.host + '&szn_Naam=' + seizoen + '&clb_ID=898')
        .subscribe((res: ResponseWedstrijden) => {
          response.wedstrijden = res.wedstrijden;
        });
    }

    return response;
  }

  getFaqs() {
    const httpHeaders = new HttpHeaders().set('Accept', 'text/html,application/xhtml+xml');
    this.http.get(environment.faqsUrl, { headers: httpHeaders, responseType: 'json' }).subscribe((response: Faqs) => {
      this._faqs.next(response);
    });
  }

  getNews() {
    const httpHeaders = new HttpHeaders().set('Accept', 'text/html,application/xhtml+xml');
    this.http.get(environment.newsUrl, { headers: httpHeaders, responseType: 'json' }).subscribe((response: News) => {
      this._news.next(response);
    });
  }
}
