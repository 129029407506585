import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolsComponent implements OnInit {
  mailresult = [];
  aantalAdressenVooraf: number;
  aantalAdressen: number;
  adressen: string[] = [];
  adresGroepen = [];
  _aantalPerGroep = 25;
  clicked: number[] = [];
  get aantalPerGroep(): string {
    return this._aantalPerGroep.toString();
  }
  set aantalPerGroep(val: string) {
    this._aantalPerGroep = Number(val);
    this.mailresult = [];
  }

  setNewValue(newValue) {
    this.mailresult = newValue;
  }

  constructor() { }

  ngOnInit() {
  }

  ontdubbelen(input: string): void {
    this.aantalAdressenVooraf = 0;
    this.aantalAdressen = 0;
    this.adressen = [];
    this.adresGroepen = [];
    this.clicked = [];
    let teller = 0;

    if (input?.length > 0) {
      input.split(/\r?\n/).forEach((splitNewLine: string) => {
        if (splitNewLine.length > 0) {
          splitNewLine.split('\t').forEach((splitTab: string) => {
            if (splitTab.length > 0) {
              this.aantalAdressenVooraf++;
              if (!this.adressen.find(a => a === splitTab)) {
                this.aantalAdressen++;
                teller++;
                this.adressen.push(splitTab);

                if (teller >= this._aantalPerGroep) {
                  teller = 0;
                  this.adresGroepen.push(this.adressen.join(';'));
                  this.adressen = [];
                }
              }
            }
          });
        }
      });

      if (this.adressen.length > 0) {
        this.adresGroepen.push(this.adressen.join(';'));
      }

      this.setNewValue(this.adresGroepen);
    }
  }


  geefCopyText(index: number): string {
    let aantal = this._aantalPerGroep;
    let tekst = "Copy " + ((index * aantal) + 1).toString() + " - ";
    if ((index + 1) * aantal > this.aantalAdressen) {
      return tekst + this.aantalAdressen.toString();
    } else {
      return tekst + ((index + 1) * aantal).toString();
    }
  }

  isClicked(index: number): boolean {
    return this.clicked.indexOf(index) > -1;
  }

  copyToClipboard(value, index: number) {
    this.clicked.push(index);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
