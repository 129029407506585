import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, style, animate, transition, query, stagger } from '@angular/animations';
import { Observable } from 'rxjs';

import { DataService } from '../../../app/services/data.service';
import { Data } from '../../../app/models/data';
import { Team } from '../../models/team';
import { Wedstrijd } from '../../models/wedstrijd';
import { BasePaginaDirective } from '../../directives/base/base-pagina.component';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
  animations: [
    trigger('listStagger', [
      transition('* <=> *', [
        query(':enter',
          [
            style({ opacity: 0, transform: 'translateY(-200px)' }),
            stagger('50ms', animate('1050ms ease-out', style({ opacity: 1, transform: 'translateY(0px)' })))
          ],
          { optional: true }
        ),
        query(':leave', animate('50ms', style({ opacity: 0 })), {
          optional: true
        })
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class TeamsComponent extends BasePaginaDirective implements OnInit {
  data$: Observable<Data>;
  currentTeamnaam: string;
  currentTeam: Team;
  teams: Team[];
  show = false;

  uitslagen: Wedstrijd[];
  toggleAlleUitslagen: boolean;
  toggleUitslagNaam: string;
  uitslagTitel: string;

  wedstrijden: Wedstrijd[];
  toggleAlleWedstrijden: boolean;
  toggleWedstrijdNaam: string;
  wedstrijdTitel: string;

  constructor(
    private _dataService: DataService) { 
      super();}

  ngOnInit() {
    this.data$ = this._dataService.data;

    if (this.currentTeam === undefined) {
      this.data$.subscribe(d => {
        if (d !== undefined && d !== null) {
          if (d.teams && d.teams.length > 0) {
            this.teams = d.teams;
            this.currentTeam = d.teams[0];
            this.currentTeamnaam = this.currentTeam.naam;
            this.uitslagen = this.currentTeam.uitslagen;
            this.wedstrijden = this.currentTeam.openWedstrijden;
          }
        }
      });
    }
    this.toggleAlleUitslagen = false;
    this.toggleUitslagNaam = "meer";
    this.uitslagTitel = "Uitslagen van team";

    this.toggleAlleWedstrijden = false;
    this.toggleWedstrijdNaam = "meer";
    this.wedstrijdTitel = "Open wedstrijden van team";
  }

  changeTeam(teamnaam: string) {
    this.currentTeam = this.teams.find(t => t.naam === teamnaam);
    this.currentTeamnaam = teamnaam;
    this.uitslagen = this.geefUitslagen(this.currentTeam.uitslagen);
    this.wedstrijden = this.geefWedstrijden(this.currentTeam.openWedstrijden);
  }

  scrollToElement(id: string): void {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  geefUitslagen(uitslagen: Wedstrijd[]): Wedstrijd[] {
    if (this.toggleAlleUitslagen) {
      return uitslagen;
    }
    return uitslagen.filter(u => u.thuis_club_id === 898 || u.uit_club_id === 898);
  }

  toggleUitslagen() {
    this.toggleAlleUitslagen = !this.toggleAlleUitslagen;
    this.toggleUitslagNaam = this.toggleAlleUitslagen ? "minder" : "meer";
    this.uitslagTitel = this.toggleAlleUitslagen ? "Uitslagen van poule" : "Uitslagen van team";
  }

  geefWedstrijden(wedstrijden: Wedstrijd[]): Wedstrijd[] {
    if (this.toggleAlleWedstrijden) {
      return wedstrijden;
    }
    return wedstrijden.filter(u => u.thuis_club_id === 898 || u.uit_club_id === 898);
  }

  toggleWedstrijden() {
    this.toggleAlleWedstrijden = !this.toggleAlleWedstrijden;
    this.toggleWedstrijdNaam = this.toggleAlleWedstrijden ? "minder" : "meer";
    this.wedstrijdTitel = this.toggleAlleWedstrijden ? "Open wedstrijden van poule" : "Open wedstrijden van team";
  }
}
