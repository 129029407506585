import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appGeboortedatumValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: GeboortedatumValidatorDirective, multi: true }
  ]
})
export class GeboortedatumValidatorDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const data = control.value.replace(/\D/g, '-').split('-');
      const d = new Date(data[2] + '-' + data[1] + '-' + data[0]);
      const isValid = d && d.getMonth() + 1 === Number(data[1]) && d.getDate() === Number(data[0]) && d.getFullYear() === Number(data[2]);

      if (isValid) {
        const vandaag = new Date();
        const year = vandaag.getFullYear();
        const month = vandaag.getMonth();
        const day = vandaag.getDate();
        // valide datum, check of ouder 5 jaar en jonger dan 60 jaar
        if (new Date(year - 5, month, day) < d) {
          return { appGebDatumValidatorTeJong: true };
        }
        if (new Date(year - 60, month, day) > d) {
          return { appGebDatumValidatorTeOud: true };
        } else {
          return null;
        }
      } else {
        return { appGebDatumValidator: true };
      }
    }
  }
}
