<div class="speurtocht">
  <div *ngIf="count === 0">
    <img class="logo" src="./assets/images/speur/logo-mondomijn.png">
    <div class="header">Beste mondomijnverlaters,<br>welkom bij jullie speurtocht</div>
    <div class="text">Eens kijken hoeveel jullie over mondomijn weten.<br>Bij elke splitsing, zijpad of kruising moeten
      jullie iets doen, dus let goed op!<br>Hopelijk kunnen jullie de route vinden, dat gaat vast lukken.</div>
    <div class="text">Veel plezier!</div>
    <div class="button" (click)="clickVolgende()">Start</div>
  </div>
  <div *ngIf="count > 0 && count !== countLaatste">
    <div *ngFor="let vraag of vragen" class="vraag" [ngClass]='{ "tonen": vraag.id === count }'>
      <div class="speurcontent">
        <div *ngIf="vraag.tekst.length > 0" class="text">{{vraag.tekst}}</div>
        <img *ngIf="vraag.image" src="./assets/images/speur/{{vraag.image}}">
        <div *ngIf="vraag.vraag" class="text" [innerHTML]="vraag.vraag"></div>
        <div *ngIf="vraag.vraagStellen">
          <label>
            <div class="text">Antwoord:</div>
            <input #antwoord name="antwoord" type="text" (keyup)="onKey($event)" placeholder="{{vraag.placeholder}}" />
          </label>
        </div>
      </div>
      <div class="button" (click)="clickVolgende(vraag, antwoord)">Volgende</div>
      <div class="link" (click)="clickTerug()">Terug</div>
      <br>
      <div>{{count}}</div>
    </div>
  </div>
  <div *ngIf="count === countLaatste">
    <div class="stage">
      <div class="box bounce-7">
        <img src="./assets/images/speur/logo_sec.jpg">
      </div>
    </div>
    <img src="./assets/images/speur/missionend.jpg">
  </div>
</div>
