<div class="home">
    <div class="grid">
        <figure [ngClass]="classTile(1)" (click)="tileClick(1, 'programma')">
            <img class="opa8" src="./assets/images/pink.jpg" alt="programma"/>
            <figcaption>
                <h2><span>W</span>edstrijde<span>n</span></h2>
                <p>Wedstrijden Uitslagen</p>
            </figcaption>			
        </figure>
        <figure [ngClass]="classTile(2)" (click)="tileClick(2, 'teams')">
            <img src="./assets/images/spelersvlakken.jpg" alt="teams"/>
            <figcaption>
                <h2>Tea<span>ms</span></h2>
                <p>Teams Coaches Trainers Trainingstijden Trainingslocatie Standen</p>
            </figcaption>			
        </figure>
        <figure [ngClass]="classTile(3)" (click)="tileClick(3, 'club')">
            <img src="./assets/images/yellow.jpg" alt="club"/>
            <figcaption>
                <h2><span>Clu</span>b</h2>
                <p>ontstaan reglement ALV</p>
            </figcaption>			
        </figure>
        <figure [ngClass]="classTile(4)" (click)="tileClick(4, 'news')">
            <img src="./assets/images/aqua.jpg" alt="news"/>
            <figcaption>
                <h2>Nie<span>uws</span></h2>
                <p>agenda nieuws</p>
            </figcaption>			
        </figure>
        <figure [ngClass]="classTile(5)" (click)="tileClick(5, 'info')">
            <img src="./assets/images/hal.jpg" alt="info"/>
            <figcaption>
                <h2>inf<span>ormatie</span></h2>
                <p>proeftraining contributie tenue hallen leeftijden fluiten privacy</p>
            </figcaption>			
        </figure>
        <figure [ngClass]="classTile(6)" (click)="tileClick(6, 'contact')">
            <img src="./assets/images/shoes.jpg" alt="contact"/>
            <figcaption>
                <h2>Co<span>ntact</span></h2>
                <p>bestuur commissies inschrijven uitschrijven</p>
            </figcaption>			
        </figure>
    </div>
</div>