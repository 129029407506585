import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, style, animate, transition, query, stagger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { NgForm, NgModel } from '@angular/forms';
// import { DomSanitizer, SafeUrl } from '@angular/platform-browser'; //

import { Observable } from 'rxjs';

import { DataService } from '../../../app/services/data.service';
import { Data } from '../../../app/models/data';
import { Bestuur } from '../../../app/models/bestuur';
import { HttpService } from '../../../app/services/http.service';
import { BasePaginaDirective } from '../../directives/base/base-pagina.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    trigger('listStagger', [
      transition('* <=> *', [
        query(':enter',
          [
            style({ opacity: 0, transform: 'translateY(-100px)' }),
            stagger('50ms', animate('750ms ease-out', style({ opacity: 1, transform: 'translateY(0px)' })))
          ],
          { optional: true }
        ),
        query(':leave', animate('50ms', style({ opacity: 0 })), {
          optional: true
        })
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})

export class ContactComponent extends BasePaginaDirective implements OnInit {
  // @ViewChild('newFile', { static: false }) newFileInput: ElementRef;

  data$: Observable<Data>;
  option: string;
  hashkey: string;
  options: string[] = [];
  bestuur: Bestuur[];
  commissies: Bestuur[];
  ngModel: NgModel;
  formSubmitted = false;
  submitSucces = false;
  // pasfoto: File;
  // base64pasfoto: string;
  // previewImg: SafeUrl;

  constructor(
    private _dataService: DataService,
    private _route: ActivatedRoute,
    private _http: HttpService
    // private _sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.data$ = this._dataService.data;
    this.options.push('Inschrijven');
    this.options.push('Uitschrijven');
    this.options.push('Bestuur');
    this.options.push('Commissies');

    this.hashkey = this._route.snapshot.queryParams['q'];
    if (this.hashkey !== undefined && this.options.indexOf(this.hashkey) > -1) {
      this.option = this.hashkey;
    } else {
      this.option = this.options[0];
    }

    this.data$.subscribe(d => {
      if (d !== undefined && d !== null) {
        this.bestuur = d.bestuur;
        this.commissies = d.commissies;
      }
    });
  }

  changeOption(optie: string) {
    this.option = optie;
  }

  scrollToElement(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  geefGegevens(contact: Bestuur): string {
    let response = contact.functie;

    if (contact.naam && contact.naam.length > 0) {
      response += '<br>' + contact.naam;
    }
    if (contact.telefoon && contact.telefoon.length > 0) {
      response += '<br>' + contact.telefoon;
    }
    if (contact.email && contact.email.length > 0) {
      response += '<br>' + contact.email;
    }

    return response;
  }

  jongerDan18(contactForm: NgForm, geboortedatumInvalid: boolean): boolean {
    if (geboortedatumInvalid) {
      return false;
    }
    if (contactForm == null || contactForm.value == null || contactForm.value.geboortedatum == null) {
      return false;
    }
    // Bepaal jonger dan 18 jaar
    const geboortedatum = contactForm.value.geboortedatum.replace(/\D/g, '-').split('-');
    const d = new Date(geboortedatum[2] + '-' + geboortedatum[1] + '-' + geboortedatum[0]);
    const vandaag = new Date();
    const year = vandaag.getFullYear();
    const month = vandaag.getMonth();
    const day = vandaag.getDate();
    if (new Date(year - 18, month, day) < d) {
      return true;
    }
    return false;
  }

  // onFileChanged($event): void {
  //   if ($event.target == null || $event.target.files.length <= 0) {
  //     return;
  //   }

  //   if ($event.target.files.length > 1) {
  //     // this.errorPasfoto = 'Er mag maar 1 foto toegevoegd worden';
  //     return;
  //   }
  //   this.pasfoto = $event.target.files[0];
  //   this.previewImg = this._sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(this.pasfoto)));
  //   const reader = new FileReader();
  //   reader.readAsDataURL(this.pasfoto);
  //   reader.onload = () => {
  //     this.base64pasfoto = btoa(reader.result.toString());
  //     console.log(this.base64pasfoto);
  //   };
  //   this.newFileInput.nativeElement.value = '';
  // }

  // deleteFile() {
  //   this.pasfoto = null;
  //   this.base64pasfoto = '';
  // }

  geefNaam(form): string {
    if (form.tussen && form.tussen.length > 0) {
      return form.tussen + ' ' + form.achternaam
    }
    return form.achternaam;
  }

  onSubmit(contactForm: NgForm) {
    if (contactForm.valid) {
      this.formSubmitted = true;
      const form = contactForm.value;

      if (this.jongerDan18(contactForm, false)) {
        this._http.post('https://formspree.io/xnqovgbz', {
          achternaam: this.geefNaam(form),
          voornaam: form.voornaam,
          geslacht: form.geslacht,
          geboortedatum: form.geboortedatum,
          adres: form.adres,
          postcode: form.postcode.replace(' ', ''),
          plaats: form.plaats,
          iban: form.iban,
          tnv: form.tnv,
          telefoon: form.telefoon,
          mobiel: form.mobiel,
          email: form.emailadres,
          'naam vader': form.naamVader,
          'mobiel vader': form.mobielVader,
          'email vader': form.emailadresVader,
          'naam moeder': form.naamMoeder,
          'mobiel moeder': form.mobielMoeder,
          'email moeder': form.emailadresMoeder,
          wedstrijden: form.wedstrijden,
          'proeftrainingen gevolgd': form.proeftrainingen ? 'Ja' : 'Nee',
          // pasfoto: this.base64pasfoto,
          opmerkingen: form.opmerkingen,
          'akkoord vastleggen gegevens': form.akkoordClub ? 'Ja' : 'Nee',
          'akkoord met privacybeleid': form.akkoordPrivacy ? 'Ja' : 'Nee',
          'akkoord maken en gebruik fotos': form.akkoordFoto ? 'Ja' : 'Nee',
          'akkoord delen NAW': form.akkoordNAW ? 'Ja' : 'Nee',
          'akkoord lid worden en afschrijving': form.akkoordLidAfschrijving ? 'Ja' : 'Nee'
        }).subscribe(
          response => {
            contactForm.resetForm();
            this.submitSucces = true;
            this.formSubmitted = false;
          }, (e: Error) => console.log(e.name + ' ' + e.message)
        );
      } else {
        this._http.post('https://formspree.io/xnqovgbz', {
          achternaam: this.geefNaam(form),
          voornaam: form.voornaam,
          geslacht: form.geslacht,
          geboortedatum: form.geboortedatum,
          adres: form.adres,
          postcode: form.postcode.replace(' ', ''),
          plaats: form.plaats,
          iban: form.iban,
          tnv: form.tnv,
          telefoon: form.telefoon,
          mobiel: form.mobiel,
          email: form.emailadres,
          wedstrijden: form.wedstrijden,
          'proeftrainingen gevolgd': form.proeftrainingen ? 'Ja' : 'Nee',
          // pasfoto: this.base64pasfoto,
          opmerkingen: form.opmerkingen,
          'akkoord vastleggen gegevens': form.akkoordClub ? 'Ja' : 'Nee',
          'akkoord met privacybeleid': form.akkoordPrivacy ? 'Ja' : 'Nee',
          'akkoord maken en gebruik fotos': form.akkoordFoto ? 'Ja' : 'Nee',
          'akkoord delen NAW': form.akkoordNAW ? 'Ja' : 'Nee',
          'akkoord lid worden en afschrijving': form.akkoordLidAfschrijving ? 'Ja' : 'Nee'
        }).subscribe(
          response => {
            contactForm.resetForm();
            this.submitSucces = true;
            this.formSubmitted = false;
          }, (e: Error) => console.log(e.name + ' ' + e.message)
        );
      }
    }
  }
}
